import React, { useEffect, useRef, useState } from 'react';
import Main from '../HomeComponent/Main';
import SecondSec from '../HomeComponent/SecondSec';
import FifthSec from '../HomeComponent/FifthSec';
import { Testimonial } from '../HomeComponent/Testiomonial';
import Packages from '../HomeComponent/Packages';
import OurPortfolio from '../HomeComponent/OurPortfolio';
import Faqs from '../HomeComponent/Faqs';
import ThirdSec from '../HomeComponent/ThirdSec';
import { Helmet } from 'react-helmet';
import SmoothScroll from '../../SmoothScroll';
import Popup from './Popup'; // Import Popup component
import Footer from '../HeaderFooterr/Footer';

const Home = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [currentSet, setCurrentSet] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  // Function to open the popup
  const openPopup = (images, index) => {
    setCurrentSet(images);
    setCurrentIndex(index);
    setIsPopupOpen(true);
  };

  // Function to close the popup
  const closePopup = () => {
    setIsPopupOpen(false);
    setCurrentSet([]);
    setCurrentIndex(0);
  };

  const smoothScrollRef = useRef(null);

  // Updated smooth scroll with callback to open popup
  const scrollSmoothly = (targetElement, callback) => {
    const targetPosition = targetElement.getBoundingClientRect().top + window.scrollY;
    const startPosition = window.scrollY;
    const distance = targetPosition - startPosition;
    const duration = 2000; // Adjust duration (in ms) for slower scrolling
    let startTime = null;

    const scrollAnimation = (currentTime) => {
      if (!startTime) startTime = currentTime;
      const timeElapsed = currentTime - startTime;
      const run = easeInOutQuad(timeElapsed, startPosition, distance, duration);
      window.scrollTo(0, run);
      if (timeElapsed < duration) {
        requestAnimationFrame(scrollAnimation);
      } else {
        // Once scroll is done, execute the callback (open popup)
        if (callback) callback();
      }
    };

    requestAnimationFrame(scrollAnimation);
  };

  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const handleScrollAndOpenPopup = (index, images) => {
    const targetElement = smoothScrollRef.current.querySelector(`#scroll-target-${index}`);
    if (targetElement) {
      scrollSmoothly(targetElement, () => {
        // This callback runs after the scroll animation finishes
        openPopup(images, index);
      });
    }
  };

  useEffect(() => {
    const fadeElements = document.querySelectorAll('.fade-up');

    const observerOptions = {
      threshold: 0.3, // Trigger when 30% of the element is visible
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('fade-in-visible');
          observer.unobserve(entry.target);
        }
      });
    }, observerOptions);

    fadeElements.forEach(element => {
      observer.observe(element);
    });
  }, []);

  return (
    <>
      <SmoothScroll ref={smoothScrollRef}>
        <Helmet>
          <title>Digital Marketing Services UK | Leading Marketing Agency</title>
          <meta
            name="description"
            content="Looking for expert digital marketing services UK? Our top-rated agency in UK offers tailored solutions for SEO, PPC, eCommerce marketing, and more."
          />
        </Helmet>

        <div className="column fade-up" id="scroll-target-0">
          <Main />
        </div>
        <div className="column fade-up" id="scroll-target-1">
          <SecondSec />
        </div>
        <FifthSec className="fade-up" id="scroll-target-2" />
        <OurPortfolio openPopup={openPopup} className="fade-up" /> {/* Pass openPopup as prop */}
        <Packages className="fade-up" id="scroll-target-3" />
        <Faqs className="fade-up" id="scroll-target-4" />
        <Testimonial className="fade-up" id="scroll-target-5" />
        <Footer className="fade-up" id="scroll-target-6" />
      </SmoothScroll>

      {/* Include Popup component and pass necessary props */}
      <Popup
        isOpen={isPopupOpen}
        images={currentSet}
        currentIndex={currentIndex}
        closePopup={closePopup}
        nextImage={() => setCurrentIndex((prev) => (prev + 1) % currentSet.length)}
        prevImage={() => setCurrentIndex((prev) => (prev - 1 + currentSet.length) % currentSet.length)}
      />
    </>
  );
};

export default Home;
