import React from 'react'

const Main = () => {
  return (
    <>
      <section className="about-section">
        <div className='container'>
            <div className='col-lg-12'>
                  <h2 className='section-title'>
                  Welcome to Apex Web Experts!</h2>
            </div>
        </div>
    </section>
    </>
  )
}

export default Main
