import React, { useState, useEffect } from 'react';
import { PiPlus } from 'react-icons/pi';
import '../../HomeComponent/faqs.css';

const Faqs = () => {
  const [openIndex, setOpenIndex] = useState(null);
  const [cursorPos, setCursorPos] = useState({ x: 0, y: 0 });

  const toggleFaq = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  // Function to update cursor position on mouse move
  const handleMouseMove = (event) => {
    setCursorPos({
      x: event.clientX,
      y: event.clientY,
    });
  };

  const faqs = [
    {
      id: 1,
      question: "What is SEO and why is it important?",
      answer: "SEO (Search Engine Optimization) is the practice of optimizing your website to improve its visibility on search engines like Google. It helps drive organic traffic, increases online visibility, and boosts credibility, ultimately leading to higher conversions and revenue.",
    },
    {
      id: 2,
      question: "How long does it take to see results from SEO?",
      answer: "SEO is a long-term strategy, and results typically start showing within 3-6 months. However, the timeline depends on factors such as competition, the current state of your website, and the strategies implemented.",
    },
    {
      id: 3,
      question: "What are the key factors that influence SEO rankings?",
      answer: "Key factors include high-quality content, backlinks, on-page optimization, user experience, site speed, mobile-friendliness, and technical SEO aspects like indexing and site architecture.",
      
    },
    {
      id: 4,
      question: "Do I need to hire an SEO agency, or can I do SEO myself?",
      answer: "While basic SEO can be done independently, hiring a professional SEO agency like Apex Web Experts ensures expertise, advanced tools, and tailored strategies that deliver better and faster results.",
    },
    {
      id: 5,
      question: "How much does SEO cost?",
      answer: "SEO costs vary depending on the project's scope, competition, and specific needs. At Apex Web Experts, we offer customized packages to suit businesses of all sizes, ensuring value for your investment.",
    },
    {
      id: 6,
      question: "Can I rank #1 on Google for any keyword?",
      answer: "Ranking #1 depends on keyword competitiveness, search volume, and the effectiveness of your SEO strategy. With the right approach, many businesses can achieve top rankings for relevant keywords.",
    }
  ];

  
  return (
    <section className="faq-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
            </div>
           
          </div>
          <div className='col-lg-2'></div>
          <div className="col-lg-8">
          <div className="faq-wrapper">
            {faqs.map((faq, index) => (
                <div key={index} className="faq-item">
                  <div
                    className="d-flex justify-content-between align-items-center question"
                    onClick={() => toggleFaq(index)}
                  >
                    <h4>{faq.question}</h4>
                    <p>
                    
                    </p>
                    <div className={`icon ${openIndex === index ? 'rotate' : ''}`}>
                      <PiPlus />
                    </div>
                  </div>
                  <div
                    className={`answer`}
                    style={{
                      maxHeight: openIndex === index ? '1000px' : '0',
                    }}
                  >
                    <p>{faq.answer}</p>
                   
                  </div>
                </div>
              ))}
            </div>           
          </div>
          <div className='col-lg-2'></div>
        </div>
      </div>
    </section>
  );
};

export default Faqs;
