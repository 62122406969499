import Main from '../AboutComponent/Main'
import AboutOurCompany from '../AboutComponent/AboutOurCompany'
import BrandsWeHaveWorked from '../AboutComponent/BrandsWeHaveWorked'
import OurAgencySnaps from '../AboutComponent/OurAgencySnaps'
import SmoothScroll from '../../SmoothScroll'
import { useRef } from 'react'
import Footer from '../HeaderFooterr/Footer'

const About = () => {
  const smoothScrollRef = useRef(null);
  return (
    <>
<SmoothScroll ref={smoothScrollRef}>
      <Main/>
      <AboutOurCompany/>
      <BrandsWeHaveWorked/>
      <OurAgencySnaps/>
      <Footer className="fade-up" id="scroll-target-6" />
      </SmoothScroll>
    </>
  )
}

export default About
