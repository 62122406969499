import React, { useEffect, useRef } from 'react';
import { BiPhone } from 'react-icons/bi';
import { FaFacebookF, FaLinkedin } from 'react-icons/fa';
import { FaArrowRight, FaFacebook, FaInstagram, FaTwitter, FaXTwitter } from 'react-icons/fa6';
import { LiaLinkedin } from 'react-icons/lia';
import { MdEmail, MdLocationPin } from 'react-icons/md';
// import Flickity from 'flickity';
// import 'flickity/css/flickity.css';
import Marquee from "react-fast-marquee";
import { Link } from 'react-router-dom';
const Footer = () => {


  return (
    <>
      <section className='footer'>
        <div className='container'>
          <div className='d-flex top-footer'>
            <div className='w-50 startup-width'>
              <h2>Let’s <br /><span>Start</span></h2>
            </div>
            <div className='w-50 startup-width'>
              <form className='footerForm'>
                <fieldset>
                  <input type='text' placeholder='Name' />
                </fieldset>
                <fieldset>
                  <input type='text' placeholder='Phone' />
                </fieldset>
                <fieldset>
                  <textarea name='message' id='message' cols='30' rows='10' placeholder='Message' />
                </fieldset>
                <fieldset>
                  <button type='submit'>Submit</button>
                </fieldset>
              </form>
            </div>
          </div>
          <div className='col-lg-12 boxRadions'>
            <div className='row w-100'>
              <div className='col-lg-5'>
                <div className='box-Sociallinks'>
                  <MdEmail />
                  contact@adverge.digital
                </div>
                <div className='box-Sociallinks'>
                  <BiPhone />
                  +1 (855)-600-3015
                </div>
                <div className='box-Sociallinks'>
                  <MdLocationPin />
                  <span>
                    Miami, Florida - USA <br />Skopje, Macedonia - Europe
                  </span>
                </div>
              </div>
              <div className='col-lg-7'>
                <div className='box-SociallinksSecond'>
                  <ul>
                    <li className='heading'>Menu</li>
                    <li><Link to='/'>Home</Link></li>
                    <li><Link to='/all-service'>Services</Link></li>
                    <li><Link to='/about-us'>About Us</Link></li>
                    <li><Link to='/contact-us'>Contact Us</Link></li>
                  </ul>
                  <div>
                  <ul>
                    <li className='heading'>Services</li>
                    <li><Link to='/logo-design'>Logo Designs</Link></li>
                    <li><Link to='/seo-service'>Seo</Link></li>
                    <li><Link to='/website-design-service'>Web Design & Development</Link></li>
                    <li><Link to='/mobile-app-service'>Mobile App Development</Link></li>
                    <li><Link to='/smm-service'>Social Media Marketing</Link></li>
                    <li><Link to='/branding-service'>Branding & Advertising</Link></li>
                  </ul>
                  <ul>
                  <li className='heading'>Follow Us</li>
                  <div className='iconsSet'>
                  <div className='Sociallinks'><LiaLinkedin /></div>
                  <div className='Sociallinks'><a href='https://www.facebook.com/profile.php?id=61568984794059' target='_blank'><FaFacebook /></a></div>
                  <div className='Sociallinks'><a href='https://www.instagram.com/apexwebexpert/' target='_blank'><FaInstagram /></a></div>
                </div>
                  </ul>
                  {/* <div className='iconsSet'>
                  <div className='Sociallinks'><LiaLinkedin /></div>
                  <div className='Sociallinks'><FaFacebook /></div>
                  <div className='Sociallinks'><FaInstagram /></div>
                </div> */}
                  </div>
                </div>
               
              </div>
             
            </div>
          </div>
          {/* <div className='col-lg-12 boxRadions'>
            <div className='row w-100'>
              <div className='col-lg-12 text-center'>
                <p>Follow Us:</p>
                <div className='iconsSet'>
                  <div className='Sociallinks'><LiaLinkedin /></div>
                  <div className='Sociallinks'><FaFacebook /></div>
                  <div className='Sociallinks'><FaInstagram /></div>
                </div>
              </div>
            </div>
          </div> */}
          <div className='col-lg-12 boxRadions marquee'>
          <Marquee pauseOnHover={true} speed={50} gradient={false}>
        <div className="carousel-cell logos">
          <img src={require('../assets/clogo2.png')} alt="Logo 2" />
        </div>
        <div className="carousel-cell logos">
          <img src={require('../assets/clogo3.png')} alt="Logo 3" />
        </div>
        <div className="carousel-cell logos">
          <img src={require('../assets/clogo4.png')} alt="Logo 4" />
        </div>
        <div className="carousel-cell logos">
          <img src={require('../assets/clogo5.png')} alt="Logo 5" />
        </div>
        <div className="carousel-cell logos">
          <img src={require('../assets/clogo6.png')} alt="Logo 6" />
        </div>
        {/* Add some placeholders or different items */}
        <div className="carousel-cell logos">
          <img src={require('../assets/clogo2.png')} alt="Logo 2 (Repeat)" />
        </div>
      </Marquee>
          
          </div>
          <hr/>
          <div className='col-lg-12 pt-4 pb-0'>
            <p className='text-center text-white'>© 2024 Apex Web Expert. All rights reserved.</p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
