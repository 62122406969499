import React, { useEffect, useRef, useState } from 'react';
import Main from '../ContactUs/Main'
import SmoothScroll from '../../SmoothScroll';
import Footer from '../HeaderFooterr/Footer';

const ContactUs = () => {
    const [offsetY, setOffsetY] = useState(0);

  const handleScroll = () => {
    setOffsetY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const smoothScrollRef = useRef(null);

  return (
    <>
    <SmoothScroll ref={smoothScrollRef}>
    <div
        className="services-container"
        style={{
          backgroundPositionY: `${offsetY * 0.5}px`, // Adjust this multiplier to control the speed
        }}
      >
   <Main/>
   </div>
   <Footer className="fade-up" id="scroll-target-6" />
   </SmoothScroll>
    </>
  )
}

export default ContactUs
