import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";

const CustomCursor = () => {
  const cursorRef = useRef(null);
  const followerRef = useRef(null);

  useEffect(() => {
    const moveCursor = (e) => {
      // Position the dot cursor instantly
      gsap.to(cursorRef.current, {
        x: e.clientX,
        y: e.clientY,
        duration: 0,
      });

      // Position the follower cursor with a smooth animation
      gsap.to(followerRef.current, {
        x: e.clientX,
        y: e.clientY,
        duration: 0.3,
        ease: "power3.out",
      });
    };

    const handleHover = (e) => {
      // Scale up follower on hover
      gsap.to(followerRef.current, { scale: 2, duration: 0.3 });
    };

    const handleLeave = (e) => {
      // Scale back down on mouse leave
      gsap.to(followerRef.current, { scale: 1, duration: 0.3 });
    };

    document.addEventListener("mousemove", moveCursor);

    // Add hover effects for interactive elements
    const hoverElements = document.querySelectorAll("a, button, .hover-effect");
    hoverElements.forEach((el) => {
      el.addEventListener("mouseenter", handleHover);
      el.addEventListener("mouseleave", handleLeave);
    });

    return () => {
      document.removeEventListener("mousemove", moveCursor);
      hoverElements.forEach((el) => {
        el.removeEventListener("mouseenter", handleHover);
        el.removeEventListener("mouseleave", handleLeave);
      });
    };
  }, []);

  return (
    <>
      <div className="cursor-dot" ref={cursorRef}></div>
      <div className="cursor-follower" ref={followerRef}></div>
    </>
  );
};

export default CustomCursor;
