import React from 'react';
import Marquee from 'react-fast-marquee';

const BrandsWeHaveWorked = () => {
  return (
    <>
      <section className="brands-we-have-worked">
        <div className="container">
          <h2 className="section-title">
            <span>Brands </span> Brands we have worked with
          </h2>
          <div className="brand-list">
            {/* First Marquee */}
            <Marquee speed={50} gradient={false}>
              <div className="carousel-cell logos">
                <img src={require('../assets/brand1.png')} alt="Logo 1" />
              </div>
              <div className="carousel-cell logos">
                <img src={require('../assets/brand2.png')} alt="Logo 2" />
              </div>
              <div className="carousel-cell logos">
                <img src={require('../assets/brand3.png')} alt="Logo 3" />
              </div>
              <div className="carousel-cell logos">
                <img src={require('../assets/brand4.png')} alt="Logo 4" />
              </div>
              <div className="carousel-cell logos">
                <img src={require('../assets/brand5.png')} alt="Logo 5" />
              </div>
              <div className="carousel-cell logos">
                <img src={require('../assets/brand6.png')} alt="Logo 6" />
              </div>
              <div className="carousel-cell logos">
                <img src={require('../assets/brand7.png')} alt="Logo 7" />
              </div>
            </Marquee>

            {/* Second Marquee with Different Position */}
            <Marquee speed={50} gradient={false} direction="right" delay={1}>
              <div className="carousel-cell logos">
                <img src={require('../assets/brand1.png')} alt="Logo 1" />
              </div>
              <div className="carousel-cell logos">
                <img src={require('../assets/brand2.png')} alt="Logo 2" />
              </div>
              <div className="carousel-cell logos">
                <img src={require('../assets/brand3.png')} alt="Logo 3" />
              </div>
              <div className="carousel-cell logos">
                <img src={require('../assets/brand4.png')} alt="Logo 4" />
              </div>
              <div className="carousel-cell logos">
                <img src={require('../assets/brand5.png')} alt="Logo 5" />
              </div>
              <div className="carousel-cell logos">
                <img src={require('../assets/brand6.png')} alt="Logo 6" />
              </div>
              <div className="carousel-cell logos">
                <img src={require('../assets/brand7.png')} alt="Logo 7" />
              </div>
            </Marquee>
          </div>
        </div>
      </section>
    </>
  );
};

export default BrandsWeHaveWorked;
