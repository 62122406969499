import React, { useState, useEffect } from 'react';
import { PiPlus } from 'react-icons/pi';
import '../../HomeComponent/faqs.css';

const Faqs = () => {
  const [openIndex, setOpenIndex] = useState(null);
  const [cursorPos, setCursorPos] = useState({ x: 0, y: 0 });

  const toggleFaq = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  // Function to update cursor position on mouse move
  const handleMouseMove = (event) => {
    setCursorPos({
      x: event.clientX,
      y: event.clientY,
    });
  };

  const faqs = [
    {
      id: 1,
      question: "How long does the logo design process take?",
      answer: "We typically deliver your logo within 7–10 business days, depending on the complexity of your requirements.",
    },
    {
      id: 2,
      question: "Can you design industry-specific logos?",
      answer: "Yes! We specialize in crafting logos for specific industries, including mechanic logos, plumbing logos, and more.",
    },
    {
      id: 3,
      question: "Do you offer revisions?",
      answer: "Absolutely! We provide multiple revisions to ensure you’re completely satisfied with the final design.",
      
    },
    {
      id: 4,
      question: "What file formats will I receive for my logo?",
      answer: "We deliver your final logo in multiple formats, including PNG, JPEG, PDF, and vector files (such as AI or EPS). These formats ensure your logo can be used across digital and print platforms without losing quality.",
    },
    {
      id: 5,
      question: "Can you redesign my existing logo?",
      answer: "Yes! We offer logo redesign services to refresh or modernize your existing logo while maintaining its core essence to ensure continuity in your branding.",
    },
    {
      id: 6,
      question: "Do you provide logos for niche businesses like cafes or automotive services? ",
      answer: "Absolutely! We specialize in creating logos for niche industries such as cafe logos, car logos Australia, and mechanic logos. No matter your business type, we design logos that represent your unique identity.",
    }
  ];

  
  return (
    <section className="faq-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
            </div>
           
          </div>
          <div className='col-lg-2'></div>
          <div className="col-lg-8">
          <div className="faq-wrapper">
            {faqs.map((faq, index) => (
                <div key={index} className="faq-item">
                  <div
                    className="d-flex justify-content-between align-items-center question"
                    onClick={() => toggleFaq(index)}
                  >
                    <h4>{faq.question}</h4>
                    <p>
                    
                    </p>
                    <div className={`icon ${openIndex === index ? 'rotate' : ''}`}>
                      <PiPlus />
                    </div>
                  </div>
                  <div
                    className={`answer`}
                    style={{
                      maxHeight: openIndex === index ? '1000px' : '0',
                    }}
                  >
                    <p>{faq.answer}</p>
                   
                  </div>
                </div>
              ))}
            </div>           
          </div>
          <div className='col-lg-2'></div>
        </div>
      </div>
    </section>
  );
};

export default Faqs;
