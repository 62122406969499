import React, { useEffect } from "react";
import { gsap } from "gsap";
import "../ServicesComponent/BrandingService/ReadyToStart.css";
import banner from '../assets/videomain.mp4';

const SecondSec = () => {
  // useEffect(() => {
  //   // GSAP Animations
  //   gsap.fromTo(
  //     ".text-box h2 span",
  //     { opacity: 0, y: 50 },
  //     { opacity: 1, y: 0, duration: 1.2, ease: "power3.out", delay: 0.5 }
  //   );

  //   gsap.fromTo(
  //     ".text-box h2",
  //     { opacity: 0, x: -50 },
  //     { opacity: 1, x: 0, duration: 1, ease: "power3.out", delay: 0.2 }
  //   );

  //   gsap.fromTo(
  //     ".text-box p",
  //     { opacity: 0, y: 50 },
  //     { opacity: 1, y: 0, duration: 1.2, ease: "power3.out", delay: 1 }
  //   );

  //   gsap.fromTo(
  //     ".cta-button",
  //     { scale: 0, opacity: 0 },
  //     { scale: 1, opacity: 1, duration: 0.8, ease: "back.out(1.7)", delay: 1.5 }
  //   );
  // }, []);

  return (
    <section className="sec-two container ReadyToStart" id="second">
      {/* Video Background */}
      <video className="background-video" autoPlay loop muted playsInline>
        <source src={banner} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <div className="container">
        <div className="text-overlay">
          <div className="row text-center">
          <div className="text-box">
                <h2>
                  Let's grow <span>together!</span>
                </h2>
                <p>
                  When you choose to work with Apex Web Experts, you're not just
                  hiring an agency. You're partnering with a dedicated team of
                  professionals invested in your success. We focus on forging
                  long-term relationships with our clients, and we're committed
                  to supporting your business growth every step of the way.
                </p>
              </div>
              {/* <p>
                  When you choose to work with Apex Web Experts, you're not just
                  hiring an agency. You're partnering with a dedicated team of
                  professionals invested in your success. We focus on forging
                  long-term relationships with our clients, and we're committed
                  to supporting your business growth every step of the way.
                </p> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SecondSec;
