import React, { useEffect, useRef, useState } from 'react';
import Main from '../ServicesComponent/MobileApplication/Main'
import BestChoice from '../ServicesComponent/MobileApplication/BestChoice';
import StageProcess from '../ServicesComponent/MobileApplication/StageProcess';
import Faqs from '../HomeComponent/Faqs';
import { Helmet } from 'react-helmet';
import ReadyToStart from '../ServicesComponent/MobileApplication/ReadyToStart';
import SmoothScroll from '../../SmoothScroll';
import Footer from '../HeaderFooterr/Footer';

const MobileApplication = () => {
    const [offsetY, setOffsetY] = useState(0);

  const handleScroll = () => {
    setOffsetY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const smoothScrollRef = useRef(null);

  return (
    <>
     <Helmet>
        <title>Mobile App Design & development company in UK</title>
        <meta
          name="description"
          content="Need top-quality mobile app design? Our expert app designers offer tailored iOS and Android app development services. As a leading app design agency in UK"
        />
      </Helmet>
      <SmoothScroll ref={smoothScrollRef}>
    <div
        className="services-container"
        style={{
          backgroundPositionY: `${offsetY * 0.5}px`, // Adjust this multiplier to control the speed
        }}
      >
      <Main/>
      <BestChoice/>
      <ReadyToStart/>
      <StageProcess/>
      {/* <Faqs/> */}
      </div>
      <Footer className="fade-up" id="scroll-target-6" />
      </SmoothScroll>
    </>
  )
}

export default MobileApplication
