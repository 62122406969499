import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import { useInView } from 'react-intersection-observer';
import 'react-vertical-timeline-component/style.min.css';
import { Tilt } from 'react-tilt';
import { Link } from 'react-router-dom';


const StageProcess = () => {
  const { ref: ref1, inView: inView1 } = useInView({ triggerOnce: true });
  const { ref: ref2, inView: inView2 } = useInView({ triggerOnce: true });
  const { ref: ref3, inView: inView3 } = useInView({ triggerOnce: true });
  const { ref: ref4, inView: inView4 } = useInView({ triggerOnce: true });

  return (
    <section className="stage-process">
      <div className="container">
        <div className="row">
          <div>
            <h2 className="section-title">
            Our Mobile Application Development Process  
            </h2>
            <p className='text-white'>
            To create exceptional mobile apps, we follow a structured development process that prioritizes your business objectives and user needs
            </p>
          </div>
          <div className="col-lg-2"></div>
          <div className="col-lg-8">
            <VerticalTimeline layout="1-column-left">
              <VerticalTimelineElement
                className={`vertical-timeline-element--work ${
                  inView1 ? 'fade-in' : 'fade-out'
                }`}
                contentStyle={{
                  background: 'linear-gradient(177deg, rgb(59 0 140 / 40%), rgb(0 0 0 / 59%))',
                  color: '#fff',
                }}
                contentArrowStyle={{ borderRight: '7px solid #ec008c' }}
                iconStyle={{ background: '#fff', color: '#8100b4' }}
                icon={'01'}
              >
                <div ref={ref1}>
                  <h3 className="vertical-timeline-element-title">Understanding Your Vision  </h3>
                  <p>
                  Our journey begins with a thorough consultation to understand your goals. As a leading custom mobile app development company, we listen to your ideas and translate them into actionable plans.  
                  </p>
                </div>
              </VerticalTimelineElement>

              <VerticalTimelineElement
                className={`vertical-timeline-element--work ${
                  inView2 ? 'fade-in' : 'fade-out'
                }`}
                contentStyle={{
                  background: 'linear-gradient(177deg, rgb(59 0 140 / 40%), rgb(0 0 0 / 59%))',
                  color: '#fff',
                }}
                contentArrowStyle={{ borderRight: '7px solid #ec008c' }}
                iconStyle={{ background: '#fff', color: '#8100b4' }}
                icon={'02'}
              >
                <div ref={ref2}>
                  <h3 className="vertical-timeline-element-title">Crafting User-Centric Designs  </h3>
                  <p>
                  User experience is at the core of our app designs. Our mobile app designer team creates intuitive interfaces that enhance usability and engagement, ensuring your app stands out in the crowded marketplace.  
                  </p>
                </div>
              </VerticalTimelineElement>

              <VerticalTimelineElement
                className={`vertical-timeline-element--work ${
                  inView3 ? 'fade-in' : 'fade-out'
                }`}
                contentStyle={{
                  background: 'linear-gradient(177deg, rgb(59 0 140 / 40%), rgb(0 0 0 / 59%))',
                  color: '#fff',
                }}
                contentArrowStyle={{ borderRight: '7px solid #ec008c' }}
                iconStyle={{ background: '#fff', color: '#8100b4' }}
                icon={'03'}
              >
                <div ref={ref3}>
                  <h3 className="vertical-timeline-element-title">Developing High-Performance Applications  </h3>
                  <p>
                  At Apex Web Experts, we focus on building scalable and reliable mobile apps. Whether it’s for iOS, Android, or cross-platform, our mobile app development service guarantees top-tier performance and security. 
                  </p>
                </div>
              </VerticalTimelineElement>

              <VerticalTimelineElement
                className={`vertical-timeline-element--work ${
                  inView4 ? 'fade-in' : 'fade-out'
                }`}
                contentStyle={{
                  background: 'linear-gradient(177deg, rgb(59 0 140 / 40%), rgb(0 0 0 / 59%))',
                  color: '#fff',
                }}
                contentArrowStyle={{ borderRight: '7px solid #ec008c' }}
                iconStyle={{ background: '#fff', color: '#8100b4' }}
                icon={'04'}
              >
                <div ref={ref4}>
                  <h3 className="vertical-timeline-element-title">Rigorous Testing for Perfection </h3>
                  <p>
                  We conduct extensive testing to eliminate bugs and ensure seamless functionality. Our rigorous quality checks ensure that your app delivers a flawless user experience.
                  </p>
                </div>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className={`vertical-timeline-element--work ${
                  inView4 ? 'fade-in' : 'fade-out'
                }`}
                contentStyle={{
                  background: 'linear-gradient(177deg, rgb(59 0 140 / 40%), rgb(0 0 0 / 59%))',
                  color: '#fff',
                }}
                contentArrowStyle={{ borderRight: '7px solid #ec008c' }}
                iconStyle={{ background: '#fff', color: '#8100b4' }}
                icon={'05'}
              >
                <div ref={ref4}>
                  <h3 className="vertical-timeline-element-title">Post-Launch Support and Maintenance   </h3>
                  <p>
                  Our partnership doesn’t end with app deployment. We provide ongoing support and updates to keep your app running smoothly and in sync with the latest technology trends.  
                  </p>
                </div>
              </VerticalTimelineElement>
            </VerticalTimeline>
          </div>
          <div className="col-lg-2"></div>
        </div>
     
					<div className="row price-plans-section">
                    <div>
            <h2 className="section-title">
              {/* <span>Pricing</span> */}
              Pricing Plans
            </h2>
          </div>
						<div className="col-sm-12 col-lg-4 mt-5">
                        <div
      className="py-4 price-inner-plan">
								<h2 className="heading-pricing-colors">Standard Package</h2>
								<h3>E-Commerce Basic</h3>
								<div className="price-heading-text">
									<h4>$600</h4>
									<p class="text-sm font-light">per person, per month</p>
								</div>
								<div className="price-list">
									<ul>
										<li>E-Commerce website Design</li>
										<li>Customize Tailor Made Design</li>
										<li>User Friendly</li>
										<li>5 Banner Designs</li>
										<li>Sliding Banner </li>
										<li>5 Stock Images </li>
										<li>100% Money Back Gurantee</li>
										<li>100% Owner Rights</li>
										<li>E-Commerce website Design</li>
										<li>Customize Tailor Made Design</li>
										<li>User Friendly</li>
										<li>5 Banner Designs</li>
									</ul>
								</div>

							</div>
						
						</div>

						<div className="col-sm-12 col-lg-4 mt-5">
                        <div
      className="py-4 price-inner-plan">
								<h2 className="heading-pricing-colors">Premium Package</h2>
									<h3>E-Commerce Professional</h3>
								<div className="price-heading-text">
									<h4>$900</h4>
									<p class="text-sm font-light">per person, per month</p>
								</div>
								<div className="price-list">
									<ul>
										<li>E-Commerce website Design</li>
										<li>Customize Tailor Made Design</li>
										<li>User Friendly</li>
										<li>5 Banner Designs</li>
										<li>Sliding Banner </li>
										<li>5 Stock Images </li>
										<li>100% Money Back Gurantee</li>
										<li>100% Owner Rights</li>
										<li>E-Commerce website Design</li>
										<li>Customize Tailor Made Design</li>
										<li>User Friendly</li>
										<li>5 Banner Designs</li>
									</ul>
								</div>

							</div>
						
						</div>


						<div className="col-sm-12 col-lg-4 mt-5">
                        <div
      className="py-4 price-inner-plan">
								<h2 className="heading-pricing-colors">Signature Package</h2>
									<h3>E-Commerce Corporate</h3>
								<div className="price-heading-text">
									<h4>$1200</h4>
									<p class="text-sm font-light">per person, per month</p>

								</div>
								<div className="price-list">
									<ul>
										<li>E-Commerce website Design</li>
										<li>Customize Tailor Made Design</li>
										<li>User Friendly</li>
										<li>5 Banner Designs</li>
										<li>Sliding Banner </li>
										<li>5 Stock Images </li>
										<li>100% Money Back Gurantee</li>
										<li>100% Owner Rights</li>
										<li>E-Commerce website Design</li>
										<li>Customize Tailor Made Design</li>
										<li>User Friendly</li>
										<li>5 Banner Designs</li>
									</ul>
								</div>

							</div>
						
						</div>

						<div className="custom-price-button" style={{paddingTop: '70px'}}>
            <Link to='/contact-us'><button>
                                Get Started
                            </button>
                            </Link>
						</div>

					</div>
				 
      </div>
    </section>
  );
};

export default StageProcess;
