import React, { useEffect, useState } from 'react';
import { BsInstagram } from 'react-icons/bs';
import { CgWebsite } from 'react-icons/cg';
import { DiAndroid } from 'react-icons/di';
import { FaAngleDown, FaAngleRight, FaAngleUp } from 'react-icons/fa';
import { HiMiniArrowSmallRight } from 'react-icons/hi2';
import { MdBrandingWatermark, MdWeb } from 'react-icons/md';
import { PiPlus } from 'react-icons/pi';
import { SiAdobeindesign } from 'react-icons/si';
import { TbSeo, TbSocial } from 'react-icons/tb';
import { Link } from 'react-router-dom';


const Main = () => {
    const [openIndex, setOpenIndex] = useState(null);
    const [openIndex1, setOpenIndex1] = useState(null);
    const [expanded, setExpanded] = useState(false);
    const [cursorPos, setCursorPos] = useState({ x: 0, y: 0 });
  
    const toggleFaq = (index) => {
      setOpenIndex(openIndex === index ? null : index);
    };
    const toggleFaq1 = (index1) => {
      setOpenIndex1(openIndex1 === index1 ? null : index1);
    };
  
    // Function to update cursor position on mouse move
    const handleMouseMove = (event) => {
      setCursorPos({
        x: event.clientX,
        y: event.clientY,
      });
    };
  
    const faqs = [
      {
        question: "01.	Customized SEO Strategies",
        answer: "Every business is unique, and so are its goals. We don’t believe in cookie-cutter solutions. Instead, we craft personalized strategies that align with your business objectives, target audience, and industry trends.",
      },
      {
        question: "02. Local and National Expertise",
        answer: "Whether you need local SEO services Sydney, local SEO Melbourne, or nationwide campaigns, we’ve got you covered. Our localized strategies help you attract customers right in your area, while our national campaigns focus on expanding your reach across Australia.",
      },
      {
        question: "03. Proven Track Record",
        answer: "As the best SEO company in Australia, we’ve helped businesses across all industries achieve higher rankings, increased traffic, and greater revenue.",
      },
      {
        question: "04.	Ecommerce SEO Excellence",
        answer: " If you’re running an online store, our eCommerce SEO Australia services will ensure your products stand out in search results, bringing more potential customers to your store.",
      },
      {
        question: "05. Local SEO Services for Melbourne and Sydney",
        answer: "Our local SEO services company specializes in optimizing your business for local searches.",
        answer1: "With our local SEO Melbourne and local SEO services Sydney, you can:",
        subList: [
          "Appear in local search results when customers search for businesses like yours.",
          "Improve your visibility on Google Maps and other location-based platforms.",
          "Drive more foot traffic to your physical location.",
          ],
      },
      {
        question: "06. Expert Team    ",
        answer: "Our skilled Australian app developers are dedicated to delivering world-class solutions.  ",
      },
      {
        question: "07. Reliable Ongoing Support  ",
        answer: "If you’re searching for a reliable website development company in Australia, look no further than Apex Web Experts. We are committed to delivering top-notch website development services in Australia that drive results.  ",
      },
      
    ];
  

    const handleFaqExpand = () => {
        setExpanded(!expanded);
    }

  return (
    <section className="hero container">
      <div className="mainHeading">
        <h2>
        Best SEO Agency in Australia | Apex Web Experts  
        </h2>
        <p>
        At 
        <i><b> Apex Web Experts</b></i>, we take pride in being recognized as the best SEO agency in Australia. Our expert team delivers powerful SEO strategies tailored to your business goals, helping you improve your search rankings, drive organic traffic, and achieve long-term success.
        <br/>
        Finding the best SEO company in Australia can be a challenge, but with our proven track record, innovative techniques, and a client-first approach, Apex Web Experts is the partner you can trust to grow your online presence.
        </p>
        <div className='custom-price-button'>
        <Link to='/contact-us'><button>
          Get Started
        </button></Link>
        </div>
      </div>
      <div className="container contentsec">
        <div className='row'>
            <div className='col-lg-12'>
                    <img src={require('../../assets/smmapexAus.png')} />
            </div>
        </div>
        <div className='row'>
            <div className='col-lg-12'>
                <h2>
                What Makes Us the Best SEO Agency in Australia?
                </h2>
                <p>
                With years of experience and hundreds of satisfied clients, we’ve earned a reputation as the best SEO agency in Australia. 
                </p>
            </div>
        </div>
        <div className='row'>
            <div className='col-lg-12'>
            <section className="faq-section">
      <div className="container">
        <div className="row">
          
          {/* <div className='col-lg-2'></div> */}
          <div className="faq-wrapper" >
            {faqs.map((faq, index) => (
                <div  className="faq-item" key={index}>
                  <div
                    className="d-flex justify-content-between align-items-center question"
                    // onClick={() => toggleFaq(index)}
                  >
                    <h4>{faq.question}</h4>
                    {/* <div className={`icon `}>
                      <PiPlus />
                    </div> */}
                    {/* Custom "Open" text following the cursor */}
                    
                  </div>
                  <div
                    // className={`answer ${openIndex === index ? 'show' : ''}`}
                    // style={
                    //   openIndex === index
                    //     ? { maxHeight: `${document.getElementById(`faq-${index}`)?.scrollHeight}px` }
                    //     : { maxHeight: 0 }
                    // }
                    // id={`faq-${index}`}
                  >
                    <p>{faq.answer}</p>
                    {faq.answer1 && <p onClick={handleFaqExpand} className='sub-answer'><span>{faq.answer1}</span>{expanded ? <FaAngleUp /> : <FaAngleDown />}</p>    }
                    
                   {expanded && <ul className='sub-list'>
                      {faq.subList &&
                        faq.subList.map((sub, index) => (
                          <li key={index}>{sub}</li>
                        ))}
                        </ul>}
                      {/* {faq.subList.map((sub, index) => (
                        <li key={index}>{sub}</li>
                        ))} */}
                    {/* </ul> */}
                  </div>
                </div>
              ))}
            </div>       
          
          {/* <div className='col-lg-2'></div> */}
        </div>
      </div>
    </section>
            </div>
        </div>
        
      </div>
    </section>
  );
};

export default Main;
