import React from 'react'
import { FaFacebook, FaInstagram, FaXTwitter } from 'react-icons/fa6'
import { LiaLinkedin } from 'react-icons/lia'

const Main = () => {
  return (
    <>
      <section className="contact-us">
        <div className="container main">
            <div className="row">
                <div className="col-lg-12">
                    <div className="section-title">
                        <h2>Portfolio</h2>
                    </div>
                </div>   
            </div>
        </div>
      </section>
    </>
  )
}

export default Main
