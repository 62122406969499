import React, { useEffect } from "react";
import { gsap } from "gsap";
import "../BrandingService/ReadyToStart.css";
import banner from '../../assets/videomain.mp4';

const ReadyToStart = () => {
  // useEffect(() => {
  //   // GSAP Animations
  //   gsap.fromTo(
  //     ".text-box h2 span",
  //     { opacity: 0, y: 50 },
  //     { opacity: 1, y: 0, duration: 1.2, ease: "power3.out", delay: 0.5 }
  //   );

  //   gsap.fromTo(
  //     ".text-box h2",
  //     { opacity: 0, x: -50 },
  //     { opacity: 1, x: 0, duration: 1, ease: "power3.out", delay: 0.2 }
  //   );

  //   gsap.fromTo(
  //     ".text-box p",
  //     { opacity: 0, y: 50 },
  //     { opacity: 1, y: 0, duration: 1.2, ease: "power3.out", delay: 1 }
  //   );

  //   gsap.fromTo(
  //     ".cta-button",
  //     { scale: 0, opacity: 0 },
  //     { scale: 1, opacity: 1, duration: 0.8, ease: "back.out(1.7)", delay: 1.5 }
  //   );
  // }, []);

  return (
    <section className="sec-two container ReadyToStart" id="second">
      {/* Video Background */}
      <video className="background-video" autoPlay loop muted playsInline>
        <source src={banner} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <div className="container">
        <div className="text-overlay">
          <div className="row text-center">
            <div className="text-box">
              <h2>
              Ready to Elevate Your Brand? Contact <span>Apex Web Experts </span>Today! 
              </h2>
              <p>
              If you’re searching for a brand agency that delivers results, look no further than Apex Web Experts. Our expert team is ready to create a brand that reflects your business’s values, connects with your audience, and stands out in the Australian market.
                <br/>
                Get in touch with us today to learn more about our branding services Australia and discover how we can help your business shine.
              </p>
              {/* <button className="cta-button">Contact Us Today</button> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ReadyToStart;
