import React from 'react'
import { FaFacebook, FaInstagram, FaXTwitter } from 'react-icons/fa6'
import { LiaLinkedin } from 'react-icons/lia'

const Main = () => {
  return (
    <>
      <section className="contact-us">
        <div className="container main">
            <div className="row">
                <div className="col-lg-12">
                    <div className="section-title">
                        <h2>Let's Work Together</h2>
                    </div>
                </div>
                
            </div>
        </div>
        <div className="container pb-4">
            <div className="row w-full align-items-center contactForm" >
            <div className='col-lg-12'>
            <div className='row w-full align-items-center'>
           <div className='col-lg-8'>
            <div className='row w-full align-items-center'>
            <div className='col-lg-6'>
                    <div  className='contactBox'>
                            <h3>
                            Phone:
                            </h3>
                            <p>
                            + 1 (855)-600-3015
                            </p>
                            <div className=' boxRadions'>
            <div className='row w-100'>
              <div className='col-lg-12 text-center'>
                <div className='iconsSet'>
                  <div className='Sociallinks'><LiaLinkedin /></div>
                  <div className='Sociallinks'><a href='https://www.facebook.com/profile.php?id=61568984794059' target='_blank'><FaFacebook /></a></div>
                  <div className='Sociallinks'><a href='https://www.instagram.com/apexwebexpert/' target='_blank'><FaInstagram /></a></div>
                </div>
              </div>
            </div>
          </div>
                        </div>
                       
                        </div>
                        <div className='col-lg-6'>
                        <div  className='contactBox'>
                            <h3>
                            Offices:
                            </h3>
                            <p>
                            Miami, Florida - USA<br/>

Skopje, Macedonia - Europe
                            </p>
                        </div>
                        </div>
                        <div className='col-lg-12'>
                           <div  className='contactBox'>
                           <h3>
                           Opening Hours: 
                            </h3>
                            <p>
                            Mon to Fri: 9.00am - 5.00pm <br/>

Sat: 10.00am - 3.30pm <br/>

Sun: Closed <br/>
                            </p>
                           </div>
                        </div>
</div>
           </div>
                        <div className='col-lg-4'>
                        <div className="contact-form">
          {/* <h2>Contact Us</h2> */}
          <form>
            <input type="text" placeholder="Full Name" />
            <input type="email" placeholder="Email Address" />
            <input type="tel" placeholder="Phone" />
            <textarea placeholder="Project Description"></textarea>
            <button type="submit">Submit</button>
          </form>
        </div>
                        </div>
                        
                       
                    </div>
                </div>
                   
            {/* <div className='col-lg-12'>
                    <div className='contactForm '>
                       <div className='row w-full align-items-center'>
                        <div className='col-lg-8'>
                           <div  className='contactBox'>
                           <h3>
                           Opening Hours: 
                            </h3>
                            <p>
                            Mon to Fri: 9.00am - 5.00pm <br/>

Sat: 10.00am - 3.30pm <br/>

Sun: Closed <br/>
                            </p>
                           </div>
                        </div>
                        <div className='col-lg-4 boxRadions'>
            <div className='row w-100'>
              <div className='col-lg-12 text-center'>
                <p>Follow Us:</p>
                <div className='iconsSet'>
                  <div className='Sociallinks'><LiaLinkedin /></div>
                  <div className='Sociallinks'><FaXTwitter /></div>
                  <div className='Sociallinks'><FaFacebook /></div>
                  <div className='Sociallinks'><FaInstagram /></div>
                </div>
              </div>
            </div>
          </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    </section>
    </>
  )
}

export default Main
