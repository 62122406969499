import React, { useEffect, useState } from 'react';
import { BsInstagram } from 'react-icons/bs';
import { CgWebsite } from 'react-icons/cg';
import { DiAndroid } from 'react-icons/di';
import { HiMiniArrowSmallRight } from 'react-icons/hi2';
import { MdBrandingWatermark, MdWeb } from 'react-icons/md';
import { PiPlus } from 'react-icons/pi';
import { SiAdobeindesign } from 'react-icons/si';
import { TbSeo, TbSocial } from 'react-icons/tb';
import { Link } from 'react-router-dom';


const Main = () => {
    const [openIndex, setOpenIndex] = useState(null);
    const [openIndex1, setOpenIndex1] = useState(null);
    const [cursorPos, setCursorPos] = useState({ x: 0, y: 0 });
  
    const toggleFaq = (index) => {
      setOpenIndex(openIndex === index ? null : index);
    };
    const toggleFaq1 = (index1) => {
      setOpenIndex1(openIndex1 === index1 ? null : index1);
    };
  
    // Function to update cursor position on mouse move
    const handleMouseMove = (event) => {
      setCursorPos({
        x: event.clientX,
        y: event.clientY,
      });
    };
  
    const faqs = [
      {
        question: "01. Tailored Solutions for Every Business  ",
        answer: "Our custom mobile app development company ensures each app we create is unique and caters to your specific business needs.  ",
      },
      {
        question: "02. Competitive Advantage  ",
        answer: "With our cutting-edge mobile app development agency services, your business can gain a competitive edge in the market.  ",
      },
      {
        question: "03. Enhanced Customer Engagement  ",
        answer: "Our mobile application development solutions are designed to improve user engagement and foster long-term customer loyalty.  ",
      },
      {
        question: "04. Seamless Integration  ",
        answer: "We ensure your app integrates seamlessly with existing systems and tools for enhanced efficiency.  ",
      },
      {
        question: "05. Cost-Effective Services    ",
        answer: "As a leading mobile app development company, we offer competitive pricing without compromising quality.  ",
      },
      {
        question: "06. Expert Team    ",
        answer: "Our skilled Australian app developers are dedicated to delivering world-class solutions.  ",
      },
      
    ];
  

  return (
    <section className="hero container">
      <div className="mainHeading">
        <h2>
        Mobile App Development Company in Australia
        </h2>
        <p>
        In today’s fast-paced digital world, having a mobile app isn’t just an option it’s a necessity.
        <i><b> Apex Web Experts</b></i>, a leading mobile app development company, specializes in creating custom mobile applications that elevate businesses. Whether you’re a startup or an established brand, our mobile application solutions ensure you stay ahead of the competition with user-friendly designs and seamless functionality.
        <br/>
        As an innovative mobile application development company, we take pride in helping Australian businesses thrive in the digital space. Our team of skilled developers transforms ideas into robust mobile applications tailored to meet your specific needs.  
        </p>
        <div className='custom-price-button'>
        <Link to='/contact-us'><button>
          Get Started
        </button></Link>
        </div>
      </div>
      <div className="container contentsec">
        <div className='row'>
            <div className='col-lg-12'>
                    <img src={require('../../assets/smmapexAus.png')} />
            </div>
        </div>
        <div className='row'>
            <div className='col-lg-12'>
                <h2>
                    <span>Benefits</span> of Choosing Apex Web Experts  
                </h2>
                <p>
                Apex Web Experts provides industry-specific solutions, making us a versatile mobile application development company. 
                </p>
            </div>
        </div>
        <div className='row'>
            <div className='col-lg-12'>
            <section className="faq-section">
      <div className="container">
        <div className="row">
          
          {/* <div className='col-lg-2'></div> */}
          <div className="faq-wrapper" >
            {faqs.map((faq, index) => (
                <div  className="faq-item" key={index}>
                  <div
                    className="d-flex justify-content-between align-items-center question"
                    // onClick={() => toggleFaq(index)}
                  >
                    <h4>{faq.question}</h4>
                    {/* <div className={`icon `}>
                      <PiPlus />
                    </div> */}
                    {/* Custom "Open" text following the cursor */}
                    
                  </div>
                  <div
                    // className={`answer ${openIndex === index ? 'show' : ''}`}
                    // style={
                    //   openIndex === index
                    //     ? { maxHeight: `${document.getElementById(`faq-${index}`)?.scrollHeight}px` }
                    //     : { maxHeight: 0 }
                    // }
                    // id={`faq-${index}`}
                  >
                    <p>{faq.answer}</p>
                  </div>
                </div>
              ))}
            </div>       
          
          {/* <div className='col-lg-2'></div> */}
        </div>
      </div>
    </section>
            </div>
        </div>
        
      </div>
    </section>
  );
};

export default Main;
