import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import { useInView } from 'react-intersection-observer';
import 'react-vertical-timeline-component/style.min.css';
import { Tilt } from 'react-tilt';
import { Link } from 'react-router-dom';


const StageProcess = () => {
  const { ref: ref1, inView: inView1 } = useInView({ triggerOnce: true });
  const { ref: ref2, inView: inView2 } = useInView({ triggerOnce: true });
  const { ref: ref3, inView: inView3 } = useInView({ triggerOnce: true });
  const { ref: ref4, inView: inView4 } = useInView({ triggerOnce: true });

  return (
    <section className="stage-process">
      <div className="container">
        <div className="row">
          <div>
            <h2 className="section-title">
            Social Media Marketing Services for Businesses Across Australia  
            </h2>
            <p className='text-white'>
            At <b><i>Apex Web Experts</i></b>, we believe that great marketing shouldn’t break the bank. Our social media packages are designed to deliver exceptional results at an affordable price.  
            </p>
          </div>
          <div className="col-lg-2"></div>
          <div className="col-lg-8">
            <VerticalTimeline layout="1-column-left">
              <VerticalTimelineElement
                className={`vertical-timeline-element--work ${
                  inView1 ? 'fade-in' : 'fade-out'
                }`}
                contentStyle={{
                  background: 'linear-gradient(177deg, rgb(59 0 140 / 40%), rgb(0 0 0 / 59%))',
                  color: '#fff',
                }}
                contentArrowStyle={{ borderRight: '7px solid #ec008c' }}
                iconStyle={{ background: '#fff', color: '#8100b4' }}
                icon={'01'}
              >
                <div ref={ref1}>
                  <h3 className="vertical-timeline-element-title">Social Media Marketing Sydney </h3>
                  <p>
                  As a trusted social media agency Sydney, we help businesses in this competitive market thrive through targeted campaigns and expert strategies.
                  </p>
                </div>
              </VerticalTimelineElement>

              <VerticalTimelineElement
                className={`vertical-timeline-element--work ${
                  inView2 ? 'fade-in' : 'fade-out'
                }`}
                contentStyle={{
                  background: 'linear-gradient(177deg, rgb(59 0 140 / 40%), rgb(0 0 0 / 59%))',
                  color: '#fff',
                }}
                contentArrowStyle={{ borderRight: '7px solid #ec008c' }}
                iconStyle={{ background: '#fff', color: '#8100b4' }}
                icon={'02'}
              >
                <div ref={ref2}>
                  <h3 className="vertical-timeline-element-title">Social Media Marketing Melbourne </h3>
                  <p>
                  Looking for a social media marketing agency Melbourne? Apex Web Experts specializes in creating tailored strategies that help Melbourne businesses connect with their audience and achieve their goals.  
                  </p>
                </div>
              </VerticalTimelineElement>

              <VerticalTimelineElement
                className={`vertical-timeline-element--work ${
                  inView3 ? 'fade-in' : 'fade-out'
                }`}
                contentStyle={{
                  background: 'linear-gradient(177deg, rgb(59 0 140 / 40%), rgb(0 0 0 / 59%))',
                  color: '#fff',
                }}
                contentArrowStyle={{ borderRight: '7px solid #ec008c' }}
                iconStyle={{ background: '#fff', color: '#8100b4' }}
                icon={'03'}
              >
                <div ref={ref3}>
                  <h3 className="vertical-timeline-element-title">Nationwide Social Media Solutions  </h3>
                  <p>
                  From Brisbane to Perth, we provide expert social media advertising services and social media marketing services to businesses across Australia. No matter where you’re located, we’re here to help.  
                  </p>
                </div>
              </VerticalTimelineElement>

              {/* <VerticalTimelineElement
                className={`vertical-timeline-element--work ${
                  inView4 ? 'fade-in' : 'fade-out'
                }`}
                contentStyle={{
                  background: 'linear-gradient(177deg, rgb(59 0 140 / 40%), rgb(0 0 0 / 59%))',
                  color: '#fff',
                }}
                contentArrowStyle={{ borderRight: '7px solid #ec008c' }}
                iconStyle={{ background: '#fff', color: '#8100b4' }}
                icon={'04'}
              >
                <div ref={ref4}>
                  <h3 className="vertical-timeline-element-title">Implementation and Management: </h3>
                  <p>
                  Once your brand is ready, we ensure it’s consistently represented across all touchpoints.
                  </p>
                </div>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className={`vertical-timeline-element--work ${
                  inView4 ? 'fade-in' : 'fade-out'
                }`}
                contentStyle={{
                  background: 'linear-gradient(177deg, rgb(59 0 140 / 40%), rgb(0 0 0 / 59%))',
                  color: '#fff',
                }}
                contentArrowStyle={{ borderRight: '7px solid #ec008c' }}
                iconStyle={{ background: '#fff', color: '#8100b4' }}
                icon={'05'}
              >
                <div ref={ref4}>
                  <h3 className="vertical-timeline-element-title">Ongoing Support: </h3>
                  <p>
                  As one of the top brand management companies, we provide ongoing support to ensure your brand remains relevant and impactful.
                  </p>
                </div>
              </VerticalTimelineElement> */}
            </VerticalTimeline>
          </div>
          <div className="col-lg-2"></div>
        </div>
     
					<div className="row price-plans-section">
                    <div>
            <h2 className="section-title">
              {/* <span>Pricing</span> */}
              Pricing Plans
            </h2>
          </div>
						<div className="col-sm-12 col-lg-4 mt-5">
                        <div
      className="py-4 price-inner-plan">
								<h2 className="heading-pricing-colors">Standard Package</h2>
								<h3>E-Commerce Basic</h3>
								<div className="price-heading-text">
									<h4>$600</h4>
									<p class="text-sm font-light">per person, per month</p>
								</div>
								<div className="price-list">
									<ul>
										<li>E-Commerce website Design</li>
										<li>Customize Tailor Made Design</li>
										<li>User Friendly</li>
										<li>5 Banner Designs</li>
										<li>Sliding Banner </li>
										<li>5 Stock Images </li>
										<li>100% Money Back Gurantee</li>
										<li>100% Owner Rights</li>
										<li>E-Commerce website Design</li>
										<li>Customize Tailor Made Design</li>
										<li>User Friendly</li>
										<li>5 Banner Designs</li>
									</ul>
								</div>

							</div>
						
						</div>

						<div className="col-sm-12 col-lg-4 mt-5">
                        <div
      className="py-4 price-inner-plan">
								<h2 className="heading-pricing-colors">Premium Package</h2>
									<h3>E-Commerce Professional</h3>
								<div className="price-heading-text">
									<h4>$900</h4>
									<p class="text-sm font-light">per person, per month</p>
								</div>
								<div className="price-list">
									<ul>
										<li>E-Commerce website Design</li>
										<li>Customize Tailor Made Design</li>
										<li>User Friendly</li>
										<li>5 Banner Designs</li>
										<li>Sliding Banner </li>
										<li>5 Stock Images </li>
										<li>100% Money Back Gurantee</li>
										<li>100% Owner Rights</li>
										<li>E-Commerce website Design</li>
										<li>Customize Tailor Made Design</li>
										<li>User Friendly</li>
										<li>5 Banner Designs</li>
									</ul>
								</div>

							</div>
						
						</div>


						<div className="col-sm-12 col-lg-4 mt-5">
                        <div
      className="py-4 price-inner-plan">
								<h2 className="heading-pricing-colors">Signature Package</h2>
									<h3>E-Commerce Corporate</h3>
								<div className="price-heading-text">
									<h4>$1200</h4>
									<p class="text-sm font-light">per person, per month</p>

								</div>
								<div className="price-list">
									<ul>
										<li>E-Commerce website Design</li>
										<li>Customize Tailor Made Design</li>
										<li>User Friendly</li>
										<li>5 Banner Designs</li>
										<li>Sliding Banner </li>
										<li>5 Stock Images </li>
										<li>100% Money Back Gurantee</li>
										<li>100% Owner Rights</li>
										<li>E-Commerce website Design</li>
										<li>Customize Tailor Made Design</li>
										<li>User Friendly</li>
										<li>5 Banner Designs</li>
									</ul>
								</div>

							</div>
						
						</div>

						<div className="custom-price-button" style={{paddingTop: '70px'}}>
            <Link to='/contact-us'><button>
                                Get Started
                            </button>
                            </Link>
						</div>

					</div>
				 
      </div>
    </section>
  );
};

export default StageProcess;
