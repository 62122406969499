import React, { useState } from 'react';
import { PiPlus } from 'react-icons/pi';

const AboutOurCompany = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFaq = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const faqs = [
    {
      question: "01. Our History",
      answer: `At Adverge, our journey began with a vision to empower businesses by transforming their digital presence. Since our inception, we have continuously evolved, embracing innovation and creativity to deliver exceptional digital marketing solutions. From our early days, we've been committed to excellence, partnering with clients to understand their unique challenges and goals. Our dedicated team has grown, bringing together experts from diverse backgrounds to offer comprehensive services ranging from SEO and content marketing to web design and development. Over the years, we've achieved significant milestones, including expanding our operations globally and maintaining a 95% annual client retention rate. Each success story and every satisfied client fuels our passion to keep pushing the boundaries of what's possible in digital marketing.   Today, Adverge stands as a testament to hard work, innovation, and collaboration. We continue to strive for excellence, ensuring our clients achieve sustained growth and increased engagement. Our history is not just a timeline of events but a story of dedication, progress, and relentless pursuit of empowering your digital presence.`,
    },
    {
      question: "02. Our Mission",
      answer: "Adverge delivers dynamic digital marketing to amplify brand growth and engagement globally. We blend innovative tech, strategic insight, and creative storytelling to connect brands with audiences, turning digital challenges into growth opportunities.",
    },
    {
      question: "03. Our Vision",
      answer: "Adverge aims to transform digital marketing with innovative solutions, enabling businesses of all sizes to engage effectively online and drive growth. We focus on creating personalized strategies and campaigns that foster connections and optimize results. Our expertise lies in understanding client needs, driving conversions, and enhancing digital excellence. Committed to client success, we envision becoming a key driver of innovation and growth in the digital era.",
    }
  ];

  return (
    <div className="container contentsec">
      <div className="row">
        <div className="col-lg-6">
          <h2>
            <span>Who we are</span> About our Company
          </h2>
          <p>
          Welcome to
            <span><i> Apex Web Experts,</i></span> your one-stop solution for cutting-edge digital services! We specialize in <span>web design, custom website development, branding, and SEO services</span>
            designed to help your business shine online.<br/> At Apex Web Experts, every business deserves a unique online presence. Whether you're a small business owner looking to establish your brand or a growing company aiming to scale, we craft tailored solutions to meet your goals. From
            <span> stunning website designs </span> to  <span>targeted SEO strategies,</span>  our team is committed to delivering excellence every step of the way.
            <br/>
            <br/>
            With years of expertise and a passion for innovation, we’ve helped businesses across industries achieve success in the ever-evolving digital world. Our mission is to bring your ideas to life and create websites that don’t just look great but perform even better.
<br/>
<br/>
Let’s work together to build your online success story.
          </p>
        </div>
        <div className="col-lg-6">
          <section className="faq-section">
            <div className="faq-wrapper">
              {faqs.map((faq, index) => (
                <div key={index} className="faq-item">
                  <div
                    className="d-flex justify-content-between align-items-center question"
                    onClick={() => toggleFaq(index)}
                  >
                    <h4>{faq.question}</h4>
                    <div className={`icon`}>
                      <PiPlus />
                    </div>
                  </div>
                  <div
                    className={`answer ${openIndex === index ? 'show' : ''}`}
                    style={
                      openIndex === index
                        ? { maxHeight: `${document.getElementById(`faq-${index}`)?.scrollHeight}px` }
                        : { maxHeight: 0 }
                    }
                    id={`faq-${index}`}
                  >
                    <p>{faq.answer}</p>
                  </div>
                </div>
              ))}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default AboutOurCompany;
