import React, { useEffect, useState } from 'react';
import { BsInstagram } from 'react-icons/bs';
import { CgWebsite } from 'react-icons/cg';
import { DiAndroid } from 'react-icons/di';
import { HiMiniArrowSmallRight } from 'react-icons/hi2';
import { IoRocket } from 'react-icons/io5';
import { MdBrandingWatermark, MdWeb } from 'react-icons/md';
import { PiPlus } from 'react-icons/pi';
import { SiAdobeindesign } from 'react-icons/si';
import { TbSeo, TbSocial } from 'react-icons/tb';
import { Tilt } from 'react-tilt';


const BestChoice = () => {
    const [openIndex, setOpenIndex] = useState(null);
    const [openIndex1, setOpenIndex1] = useState(null);
    const [cursorPos, setCursorPos] = useState({ x: 0, y: 0 });
  
    const toggleFaq = (index) => {
      setOpenIndex(openIndex === index ? null : index);
    };
    const toggleFaq1 = (index1) => {
      setOpenIndex1(openIndex1 === index1 ? null : index1);
    };
  
    // Function to update cursor position on mouse move
    const handleMouseMove = (event) => {
      setCursorPos({
        x: event.clientX,
        y: event.clientY,
      });
    };
  
    const faqs = [
      {
        question: "01. Enhanced Brand Awareness",
        answer: "Social Media Marketing helps increase your brand's visibility by reaching a larger audience on platforms like Facebook, Instagram, Twitter, and LinkedIn. Consistent and strategic posting ensures your brand stays top-of-mind.",
      },
      {
        question: "03. Improved Brand Loyalty",
        answer: "Regular interaction and consistent brand messaging on social media platforms help build trust and loyalty among customers. A strong social media presence can lead to a community of devoted brand advocates.",
      },
      {
        question: "05. Cost-Effective Marketing",
        answer: "Compared to traditional advertising methods, Social Media Marketing offers a cost-effective way to promote products and services. Paid social media campaigns can be tailored to fit any budget, ensuring maximum ROI.",
      },
      {
        question: "07. Competitive Advantage",
        answer: "In today's competitive digital landscape, a strong Social Media Marketing strategy can give businesses an edge over competitors. By staying active and responsive on social media, companies can attract more customers and gain market share.",
      },
      
    ];
  
    const faqs1 = [
        {
          question: "02. Targeted Engagement",
          answer: "Through precise targeting and engaging content, Social Media Marketing attracts the right audience to your brand. By interacting with followers through comments, likes, and shares, businesses can build stronger relationships and foster loyalty.",
        },
      {
        question: "04. Higher Conversion Rates",
        answer: "Social Media Marketing strategies, such as social media ads and shoppable posts, directly lead to higher conversion rates. By reaching potential customers where they spend their time, businesses can drive traffic and boost sales.",
      },
      {
        question: "06. Real-Time Performance Analysis",
        answer: "Social media platforms provide robust analytics tools to monitor the performance of your campaigns in real time. This allows businesses to make data-driven decisions and optimize strategies for better results.",
      },
      {
        question: "08. Community Building",
        answer: "For businesses looking to foster a sense of community, Social Media Marketing is an ideal tool. By engaging with your audience through content, discussions, and events, you can create a loyal and active community around your brand.",
      },
      
    ];
  
    const defaultOptions = {
        reverse:        false,  // reverse the tilt direction
        max:            35,     // max tilt rotation (degrees)
        perspective:    1000,   // Transform perspective, the lower the more extreme the tilt gets.
        scale:          1.1,    // 2 = 200%, 1.5 = 150%, etc..
        speed:          1000,   // Speed of the enter/exit transition
        transition:     true,   // Set a transition on enter/exit.
        axis:           null,   // What axis should be disabled. Can be X or Y.
        reset:          true,    // If the tilt effect has to be reset on exit.
        easing:         "cubic-bezier(.03,.98,.52,.99)",    // Easing on enter/exit.
    }

  return (
    <section className="hero container">
    
      <div className="container contentsec">
      <div className='row'>
            <div className='col-lg-12'>
                <h2>
                    <span>Why Choose </span> Apex Web Experts for Logo Design in Australia?
                </h2>
                <p>Choosing the right logo design partner is crucial for creating a brand that resonates with your audience. At
                    <span> Apex Web Experts </span> we provide a wide range of logo design services, including custom designs for specific industries such as cafes, mechanics, and even coffee shops. 
                </p>
                <h3>Here’s what makes our services stand out:</h3>
            </div>
            <div className='col-lg-4'>
            <Tilt   options={{
        max: 45,
        scale: 1,
        speed: 450
      }}
      className="tintBg services-box py-4">
      <div>
      <div className="icon"><IoRocket/></div>
      <h3 className='heading'>Tailored Solutions</h3>
      <p>
      Every logo we design is customized to your brand’s unique personality and goals. Our logo design services follow a structured process to ensure your complete satisfaction:  
      </p>
      </div>
    </Tilt>
            </div>
            <div className='col-lg-4'>
            <Tilt   options={{
        max: 45,
        scale: 1,
        speed: 450
      }}
      className="tintBg services-box py-4">
      <div>
      <div className="icon"><IoRocket/></div>
      <h3 className='heading'>Experienced Designers</h3>
      <p>
      With years of expertise, our team can create designs for any industry, whether you're looking for a mechanic logo or a coffee shop logo.
      </p>
      </div>
    </Tilt>
            </div>
            <div className='col-lg-4'>
            <Tilt   options={{
        max: 45,
        scale: 1,
        speed: 450
      }}
      className="tintBg services-box py-4">
      <div>
      <div className="icon"><IoRocket/></div>
      <h3 className='heading'>Nationwide Reach</h3>
      <p>
      From logo design Melbourne to logo designer Sydney and logo design Brisbane, we serve businesses across Australia.
      </p>
      </div>
    </Tilt>
            </div>
            <div className='col-lg-4'>
            <Tilt   options={{
        max: 45,
        scale: 1,
        speed: 450
      }}
      className="tintBg services-box py-4">
      <div>
      <div className="icon"><IoRocket/></div>
      <h3 className='heading'>Logo Design Melbourne </h3>
      <p>
      If you’re searching for a logo designer Melbourne, we offer creative and custom designs for businesses in Melbourne. 
      </p>
      </div>
    </Tilt>
            </div>
            <div className='col-lg-4'>
            <Tilt   options={{
        max: 45,
        scale: 1,
        speed: 450
      }}
      className="tintBg services-box py-4">
      <div>
      <div className="icon"><IoRocket/></div>
      <h3 className='heading'>Logo Designer Sydney  </h3>
      <p>
      Apex Web Experts proudly provides logo design Sydney services tailored to your industry. 
      </p>
      </div>
    </Tilt>
            </div>
            <div className='col-lg-4'>
            <Tilt   options={{
        max: 45,
        scale: 1,
        speed: 450
      }}
      className="tintBg services-box py-4">
      <div>
      <div className="icon"><IoRocket/></div>
      <h3 className='heading'>Logo Design Brisbane  </h3>
      <p>
      Businesses in Brisbane trust us for unique and meaningful logo designs. Whether you need an australian logo or an electrical logo, we deliver top-notch designs to meet your needs.  
      </p>
      </div>
    </Tilt>
            </div>
           
        </div>    
      </div>
    </section>
  );
};

export default BestChoice;
