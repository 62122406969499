import React, { useEffect, useRef, useState } from 'react';
import Main from '../PortfolioComponents/Main'
import SecondComp from '../PortfolioComponents/SecondComp';
import { Testimonial } from '../HomeComponent/Testiomonial';
import Packages from '../HomeComponent/Packages';
import Faqs from '../HomeComponent/Faqs';
import SmoothScroll from '../../SmoothScroll';
import Popup from './Popup';
import Footer from '../HeaderFooterr/Footer';

const Portfolio = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [currentSet, setCurrentSet] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  // Function to open the popup
  const openPopup = (images, index) => {
    setCurrentSet(images);
    setCurrentIndex(index);
    setIsPopupOpen(true);
  };

  // Function to close the popup
  const closePopup = () => {
    setIsPopupOpen(false);
    setCurrentSet([]);
    setCurrentIndex(0);
  };

    const [offsetY, setOffsetY] = useState(0);

  const handleScroll = () => {
    setOffsetY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const smoothScrollRef = useRef(null);

  return (
    <>
    <SmoothScroll ref={smoothScrollRef}>
    <div
        className="services-container"
        style={{
          backgroundPositionY: `${offsetY * 0.5}px`, // Adjust this multiplier to control the speed
        }}
      >
   <Main/>
   <SecondComp openPopup={openPopup} />
   <Packages/>
   <Faqs/>
   <Testimonial/>
   </div>
   <Footer className="fade-up" id="scroll-target-6" />
   </SmoothScroll>
   {/* Include Popup component and pass necessary props */}
   <Popup
        isOpen={isPopupOpen}
        images={currentSet}
        currentIndex={currentIndex}
        closePopup={closePopup}
        nextImage={() => setCurrentIndex((prev) => (prev + 1) % currentSet.length)}
        prevImage={() => setCurrentIndex((prev) => (prev - 1 + currentSet.length) % currentSet.length)}
      />
    </>
  )
}

export default Portfolio
