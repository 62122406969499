import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";
import "./Testimonial.css";

export const Testimonial = () => {
  const testimonial = [
    {
    name: "Sarah J.",
    img: require("../assets/client1.png"),
    text: "Apex Web Experts completely transformed our online presence! Our new website is stunning, user-friendly, and optimized for SEO. The team listened to our needs and delivered beyond our expectations. We’ve seen a significant increase in website traffic and inquiries. Highly recommended!",
    stars: 4,
    position: "Owner of Luxe Interiors",
    },
    {
      name: " Mark T.",
      img: require("../assets/client1.png"),
      text: "Working with Apex Web Experts was a game-changer for my small business. They designed a custom e-commerce website that perfectly fits my brand and made managing my online store seamless. Their professionalism, attention to detail, and creativity are unmatched. I couldn't be happier!",
      stars: 4,
      position: "Founder of Trendy Threads",
    },
    {
      name: "Rachel S.",
      img: require("../assets/client1.png"),
      text: "Apex Web Experts truly live up to their name. Their team developed a sleek and modern website for us and optimized it with on-page SEO. We now rank higher on search engines and attract more customers than ever. They’re my go-to for all web-related needs!",
      stars: 4,
      position: "Marketing Manager at Elite Realty Group",
    },
    {
      name: "Tom H.",
      img: require("../assets/client1.png"),
      text: "The team at Apex Web Experts exceeded my expectations. From the initial consultation to the final launch, they communicated every step of the process and delivered an outstanding product. Their expertise in web design and digital marketing has helped our business grow tremendously.",
      stars: 4,
      position: "CEO of Global Tech Solutions",
    },
    {
      name: "Jennifer K.",
      img: require("../assets/client1.png"),
      text: "Apex Web Experts brought our vision to life with a beautiful, professional website. Their ability to balance creativity and functionality is unmatched. We’ve received tons of compliments from clients, and our engagement rates have skyrocketed. Thank you for your incredible work!",
      stars: 4,
      position: "Director at Inspire Nonprofit",
    },
    {
      name: "Peter L.",
      img: require("../assets/client1.png"),
      text: "Choosing Apex Web Experts was the best decision for our business. They revamped our outdated website and optimized it for mobile, making it easy for customers to navigate. Their SEO strategies helped us climb search rankings, and our sales have doubled since launch. Outstanding service!",
      stars: 4,
      position: "Owner of Green Earth Supplies",
    },
    // {
    //   name: "Isatou Senghore",
    //   img: require("../assets/client1.png"),
    //     text: "Working With Apex  Creative  designs was a game changer for my business - their designs & branding strategies have  helped us take our business to the revolusionery",
    //   stars: 4,
    // },
    // {
    //   name: "Isatou Senghore",
    //   img: require("../assets/client1.png"),
    //   text: "Working With Apex  Creative  designs was a game changer for my business - their designs & branding strategies have  helped us take our business to the revolusionery",
    //   stars: 4,
    // },
    // {
    //   name: "Isatou Senghore",
    //   img: require("../assets/client1.png"),
    //   text: "Working With Apex  Creative  designs was a game changer for my business - their designs & branding strategies have  helped us take our business to the revolusionery",
    //   stars: 4,
    // },
    // {
    //   name: "Isatou Senghore",
    //   img: require("../assets/client1.png"),
    //   text: "Working With Apex  Creative  designs was a game changer for my business - their designs & branding strategies have  helped us take our business to the revolusionery",
    //   stars: 4,
    // },
  ];

  return (
    <>
      <section className="teals-testimonials">
        <div className="container">
          <div>
            <div className="testimonial-title">
              <p>Testimonials</p>
              <h2>
                Satisfied <span>Client’s</span>
              </h2>
              <p className="pb-2">
                Solutions to fit every budget and need - our flexible pricing
                and plans make it easy for you to choose the perfect solution
                for your brand!
              </p>
            </div>
          </div>
          <div className="carousel">
            <Swiper
              // modules={[Autoplay]}
              spaceBetween={30}
              slidesPerView={3}
              autoplay={{ delay: 3000 }}
              breakpoints={{
                // Responsive breakpoints
                1024: {
                  slidesPerView: 3,
                },
                768: {
                  slidesPerView: 2,
                },
                480: {
                  slidesPerView: 1,
                  },
                576: {
                  slidesPerView: 1,
                },
                0: {
                  slidesPerView: 1,
                },
              }}
              className="testimonial-swiper"
            >
              {testimonial.map((item, index) => (
                <SwiperSlide key={index}>
                  <div className="testimonials_web mb-4">
                    <p>{item.text}</p>
                    <div className="testBox">
                      {/* <div className="img-box">
                        <img src={item.img} alt={item.name} />
                      </div> */}
                      <div>
                        <h3>{item.name}</h3>
                        <p>{item.position}</p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
};
