import React, { useEffect, useState } from 'react';
import { BsInstagram } from 'react-icons/bs';
import { CgWebsite } from 'react-icons/cg';
import { DiAndroid } from 'react-icons/di';
import { HiMiniArrowSmallRight } from 'react-icons/hi2';
import { MdBrandingWatermark, MdWeb } from 'react-icons/md';
import { PiPlus } from 'react-icons/pi';
import { SiAdobeindesign } from 'react-icons/si';
import { TbSeo, TbSocial } from 'react-icons/tb';
import { Link } from 'react-router-dom';


const Main = () => {
    const [openIndex, setOpenIndex] = useState(null);
    const [openIndex1, setOpenIndex1] = useState(null);
    const [cursorPos, setCursorPos] = useState({ x: 0, y: 0 });
  
    const toggleFaq = (index) => {
      setOpenIndex(openIndex === index ? null : index);
    };
    const toggleFaq1 = (index1) => {
      setOpenIndex1(openIndex1 === index1 ? null : index1);
    };
  
    // Function to update cursor position on mouse move
    const handleMouseMove = (event) => {
      setCursorPos({
        x: event.clientX,
        y: event.clientY,
      });
    };
  
    const faqs = [
      {
        question: "01. Enhanced Brand Recognition and Recall",
        answer: "A strong brand ensures your business is instantly recognizable. Our brand services Australia focus on creating unique and memorable brand identities that leave a lasting impression on your audience.",
      },
      {
        question: "02. Building Customer Loyalty",
        answer: "Customers connect with brands that align with their values and emotions. Our branding strategies are designed to foster trust, loyalty, and long-term relationships with your customers.",
      },
      {
        question: "03. Competitive Advantage in Your Industry",
        answer: "In crowded markets, differentiation is key. As the brand agency, we help you stand out from competitors by crafting a unique voice and personality for your business.",
      },
      {
        question: "04. Increased Credibility and Trust",
        answer: "A cohesive and professional brand boosts your credibility, making it easier to attract customers and business partners.",
      },
      
    ];
  

  return (
    <section className="hero container">
      <div className="mainHeading">
        <h2>
        The Brand Agency Design Apex Web Experts Australia
        </h2>
        <p>
        At <b>Apex Web Experts</b>, we are proud to be recognized as the brand agency in Australia that businesses trust to elevate their identity and reputation. Whether you're a startup or an established business, our corporate branding services and business branding services are tailored to help you stand out in today's competitive market. the role of brand developers in Australia has never been more important.
        </p>
        <div className='custom-price-button'>
        <Link to='/contact-us'><button>
          Get Started
        </button></Link>
        </div>
      </div>
      <div className="container contentsec">
        <div className='row'>
            <div className='col-lg-12'>
                    <img src={require('../../assets/smmapexAus.png')} />
            </div>
        </div>
        <div className='row'>
            <div className='col-lg-12'>
                <h2>
                    <span>Benefits</span> of Partnering with Apex Web Experts for Brand Services in Australia
                </h2>
                <p>
                As the agency Australia businesses trust for branding, we offer unmatched value to our clients. Here’s how our branding expertise can transform your business:
                </p>
            </div>
        </div>
        <div className='row'>
            <div className='col-lg-12'>
            <section className="faq-section">
      <div className="container">
        <div className="row">
          
          {/* <div className='col-lg-2'></div> */}
          <div className="faq-wrapper" >
            {faqs.map((faq, index) => (
                <div  className="faq-item" key={index}>
                  <div
                    className="d-flex justify-content-between align-items-center question"
                    // onClick={() => toggleFaq(index)}
                  >
                    <h4>{faq.question}</h4>
                    {/* <div className={`icon `}>
                      <PiPlus />
                    </div> */}
                    {/* Custom "Open" text following the cursor */}
                    
                  </div>
                  <div
                    // className={`answer ${openIndex === index ? 'show' : ''}`}
                    // style={
                    //   openIndex === index
                    //     ? { maxHeight: `${document.getElementById(`faq-${index}`)?.scrollHeight}px` }
                    //     : { maxHeight: 0 }
                    // }
                    // id={`faq-${index}`}
                  >
                    <p>{faq.answer}</p>
                  </div>
                </div>
              ))}
            </div>          
          
          {/* <div className='col-lg-2'></div> */}
        </div>
      </div>
    </section>
            </div>
        </div>
        
      </div>
    </section>
  );
};

export default Main;
