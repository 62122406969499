import React, { useState, useEffect, useRef } from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../assets/logoApex.png';
import { Link } from 'react-router-dom';

const Header = () => {
  const [scrolled, setScrolled] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [toggleTabName, setToggleTabName] = useState("Home");
  const [sideMenuOpen, setSideMenuOpen] = useState(false); // State for the side menu
  const dropdownRef = useRef(null);
  const sideMenuRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };

    const handleClickOutside = (event) => {
      if (
        (dropdownRef.current && !dropdownRef.current.contains(event.target)) ||
        (sideMenuRef.current && !sideMenuRef.current.contains(event.target))
      ) {
        setDropdownOpen(false);
        setSideMenuOpen(false); // Close side menu if clicked outside
      }
    };

    window.addEventListener('scroll', handleScroll);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <Navbar expand="lg" className={`header-navbar ${scrolled ? 'scrolled' : ''}`}>
      <Container>
        <div className="header-content">
          <div className="mainMenu">
            <Link to="/" className="logo">
              <img width={200} src={logo} alt="Apex Logo" />
            </Link>
            <div className="toggle-menu" ref={dropdownRef}>
              <ul
                className={`menu-list ${dropdownOpen ? 'active' : ''}`}
                onMouseEnter={() => setDropdownOpen(true)} // Open dropdown on hover
                onMouseLeave={() => setDropdownOpen(false)} // Close dropdown when hover ends
              >
                <h3>
                  <span>{toggleTabName}</span>
                  <svg
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                    style={{
                      rotate: dropdownOpen ? '45deg' : '',
                      transition: 'all 0.3s linear',
                    }}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="Icon / Plus">
                      <path
                        id="Rectangle 3"
                        d="M10 1V3.6C10 5.84021 10 6.96031 9.56403 7.81596C9.18053 8.56861 8.56861 9.18053 7.81596 9.56403C6.96031 10 5.84021 10 3.6 10H1"
                        stroke="currentColor"
                        vectorEffect="non-scaling-stroke"
                        strokeLinecap="round"
                      />
                      <path
                        id="Rectangle 4"
                        d="M19 10H16.4C14.1598 10 13.0397 10 12.184 10.436C11.4314 10.8195 10.8195 11.4314 10.436 12.184C10 13.0397 10 14.1598 10 16.4V19"
                        stroke="currentColor"
                        vectorEffect="non-scaling-stroke"
                        strokeLinecap="round"
                      />
                    </g>
                  </svg>
                </h3>
                <div className={`dropdown-content ${dropdownOpen ? 'active' : ''}`}>
                  <Link onClick={() => setToggleTabName("Home")}  to="/" className="dropdown-link">Home</Link>
                  <Link onClick={() => setToggleTabName("About")} to="/about-us" className="dropdown-link">About Us</Link>
                  <Link onClick={() => setToggleTabName("Services")} to="/all-service" className="dropdown-link">Services</Link>
                  <Link onClick={() => setToggleTabName("Portfolio")} to="/portfolio" className="dropdown-link">Portfolio</Link>
                  <Link onClick={() => setToggleTabName("Contact Us")} to="/contact-us" className="dropdown-link">Contact Us</Link>
                </div>
              </ul>
            </div>
          </div>
          <Link to='/contact-us'> <button className="btn btn-outline-light">Get Started</button></Link>
        </div>
      </Container>
    </Navbar>
  );
};

export default Header;
