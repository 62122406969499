import React, { useEffect, useState } from "react";

const HoverImageComponent = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      // Update to the next image, loop back to the first one if necessary
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 2000); // Change image every 3 seconds

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [images.length]);

  return (
    <div className="image-container">
      <img
        src={images[currentIndex]}
        alt={`Slide ${currentIndex}`}
        className="auto-image"
        style={{
          width: "100%",
          height: "100%",
          transition: "opacity 0.5s ease-in-out",
        }}
      />
    </div>
  );
};

export default HoverImageComponent;
