import React, { useEffect, useState } from 'react';
import { BsInstagram } from 'react-icons/bs';
import { CgWebsite } from 'react-icons/cg';
import { DiAndroid } from 'react-icons/di';
import { HiMiniArrowSmallRight } from 'react-icons/hi2';
import { MdBrandingWatermark, MdWeb } from 'react-icons/md';
import { PiPlus } from 'react-icons/pi';
import { SiAdobeindesign } from 'react-icons/si';
import { TbSeo, TbSocial } from 'react-icons/tb';
import { Link } from 'react-router-dom';


const Main = () => {
    const [openIndex, setOpenIndex] = useState(null);
    const [openIndex1, setOpenIndex1] = useState(null);
    const [cursorPos, setCursorPos] = useState({ x: 0, y: 0 });
  
    const toggleFaq = (index) => {
      setOpenIndex(openIndex === index ? null : index);
    };
    const toggleFaq1 = (index1) => {
      setOpenIndex1(openIndex1 === index1 ? null : index1);
    };
  
    // Function to update cursor position on mouse move
    const handleMouseMove = (event) => {
      setCursorPos({
        x: event.clientX,
        y: event.clientY,
      });
    };
  
    const faqs = [
      {
        question: "01. Customized Solutions for Every Business    ",
        answer: "No two businesses are the same, which is why we provide fully customized solutions that reflect your unique goals and branding.  ",
      },
      {
        question: "02. Scalable and Future-Proof Designs    ",
        answer: "Our websites are built to grow your business. We use the latest technologies to ensure scalability and reliability.  ",
      },
      {
        question: "03. High-Performance Websites    ",
        answer: "Speed and performance are critical for user satisfaction. As a trusted website development company in Australia, we ensure your website is fast, responsive, and optimized for conversions.  ",
      },
      {
        question: "04. Expert Developers  ",
        answer: "Our team of skilled developers specializes in custom website design services and advanced web technologies.  ",
      },
      {
        question: "05. Local Expertise    ",
        answer: "We’re an Australian web design company with a deep understanding of the local market.  ",
      },
      {
        question: "06. Expert Team    ",
        answer: "Our skilled Australian app developers are dedicated to delivering world-class solutions.  ",
      },
      {
        question: "07. Reliable Ongoing Support  ",
        answer: "If you’re searching for a reliable website development company in Australia, look no further than Apex Web Experts. We are committed to delivering top-notch website development services in Australia that drive results.  ",
      },
      
    ];
  

  return (
    <section className="hero container">
      <div className="mainHeading">
        <h2>
        Website Development Company in Australia  
        </h2>
        <p>
        A strong online presence is essential for businesses in today’s digital-first world. At 
        <i><b> Apex Web Experts</b></i>, we are a trusted website development company in Australia, offering tailored solutions to help businesses create stunning, functional, and high-performing websites. Whether you’re a small business looking for an affordable package or an enterprise in need of custom solutions, we are here to bring your vision to life.  
        <br/>
        As a leader in website development, we specialize in delivering professional, responsive, and user-friendly websites that drive results. Our comprehensive website development services in Australia are designed to help you stand out in a competitive online market.  
        </p>
        <div className='custom-price-button'>
        <Link to='/contact-us'><button>
          Get Started
        </button></Link>
        </div>
      </div>
      <div className="container contentsec">
        <div className='row'>
            <div className='col-lg-12'>
                    <img src={require('../../assets/smmapexAus.png')} />
            </div>
        </div>
        <div className='row'>
            <div className='col-lg-12'>
                <h2>
                    <span>Benefits</span> of Choosing Apex Web Experts for Website Development  
                </h2>
                <p>
                As a trusted web design company in Australia, we take pride in delivering websites that exceed expectations. 
                </p>
            </div>
        </div>
        <div className='row'>
            <div className='col-lg-12'>
            <section className="faq-section">
      <div className="container">
        <div className="row">
          
          {/* <div className='col-lg-2'></div> */}
          <div className="faq-wrapper" >
            {faqs.map((faq, index) => (
                <div  className="faq-item" key={index}>
                  <div
                    className="d-flex justify-content-between align-items-center question"
                    // onClick={() => toggleFaq(index)}
                  >
                    <h4>{faq.question}</h4>
                    {/* <div className={`icon `}>
                      <PiPlus />
                    </div> */}
                    {/* Custom "Open" text following the cursor */}
                    
                  </div>
                  <div
                    // className={`answer ${openIndex === index ? 'show' : ''}`}
                    // style={
                    //   openIndex === index
                    //     ? { maxHeight: `${document.getElementById(`faq-${index}`)?.scrollHeight}px` }
                    //     : { maxHeight: 0 }
                    // }
                    // id={`faq-${index}`}
                  >
                    <p>{faq.answer}</p>
                  </div>
                </div>
              ))}
            </div>       
          
          {/* <div className='col-lg-2'></div> */}
        </div>
      </div>
    </section>
            </div>
        </div>
        
      </div>
    </section>
  );
};

export default Main;
