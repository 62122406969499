import React from 'react';

const Popup = ({ isOpen, images, currentIndex, closePopup, nextImage, prevImage }) => {
  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <button className="close-button" onClick={closePopup}>
          ✖
        </button>
        <img src={images[currentIndex]} alt="Popup" className="popup-image" />
        <div className="popup-controls">
          <button onClick={prevImage}>←</button>
          <button onClick={nextImage}>→</button>
        </div>
      </div>
    </div>
  );
};

export default Popup;
