import React, { useEffect, useState } from 'react';
import { FaArrowUp } from 'react-icons/fa'; // For the arrow icon
import './ScrollToTopButton.css';

const ScrollToTopButton = () => {
  const [scrolling, setScrolling] = useState(false);
  const [scrollProgress, setScrollProgress] = useState(0);

  // Handle scroll position changes
  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const documentHeight = document.documentElement.scrollHeight - window.innerHeight;
    const scrollProgress = (scrollPosition / documentHeight) * 100;

    setScrollProgress(scrollProgress);

    if (scrollPosition > 200) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  // Scroll to top functionality
  const ScrollToTopButton = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Add scroll event listener
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="scroll-to-top">
      {/* Scroll progress bar */}
      <div className="scroll-progress-bar" style={{ width: `${scrollProgress}%` }}></div>

      {/* Scroll to top button */}
      {scrolling && (
        <div className="scroll-button" onClick={ScrollToTopButton}>
          <FaArrowUp />
        </div>
      )}
    </div>
  );
};

export default ScrollToTopButton;
