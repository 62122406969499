import React, { useState, useEffect } from 'react';
import { PiPlus } from 'react-icons/pi';
import '../../HomeComponent/faqs.css';

const Faqs = () => {
  const [openIndex, setOpenIndex] = useState(null);
  const [cursorPos, setCursorPos] = useState({ x: 0, y: 0 });

  const toggleFaq = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  // Function to update cursor position on mouse move
  const handleMouseMove = (event) => {
    setCursorPos({
      x: event.clientX,
      y: event.clientY,
    });
  };

  const faqs = [
    {
      id: 1,
      question: "Which platforms do you specialize in for social media marketing? ",
      answer: "We specialize in Facebook, Instagram, LinkedIn, Twitter, and other popular platforms. Our services are tailored to your business and target audience. ",
    },
    {
      id: 2,
      question: "How do you measure the success of a social media campaign?",
      answer: "We track metrics like engagement, reach, conversions, and ROI through detailed analytics and reports, ensuring your campaigns meet your goals.  ",
    },
    {
      id: 3,
      question: "Do you offer services for specific industries?",
      answer: "Yes, we create tailored strategies for various industries, including e-commerce, local services, and corporate businesses.  ",
      
    },
    {
      id: 4,
      question: "Can you create custom social media packages for my business?",
      answer: "Absolutely! We offer flexible social media packages designed to fit your specific needs and budget.  ",
    },
    {
      id: 5,
      question: "Do you provide services for businesses outside Sydney and Melbourne?  ",
      answer: "Yes, we offer nationwide services, including social media marketing Sydney, social media marketing Melbourne, and more, helping businesses across Australia succeed.",
    },
    {
      id: 6,
      question: "How quickly can I see results from social media marketing?",
      answer: "Results vary depending on the campaign, but most clients start seeing improvements in engagement and reach within the first month of working with us.  ",
    }
  ];

  
  return (
    <section className="faq-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
            </div>
           
          </div>
          <div className='col-lg-2'></div>
          <div className="col-lg-8">
          <div className="faq-wrapper">
            {faqs.map((faq, index) => (
                <div key={index} className="faq-item">
                  <div
                    className="d-flex justify-content-between align-items-center question"
                    onClick={() => toggleFaq(index)}
                  >
                    <h4>{faq.question}</h4>
                    <p>
                    
                    </p>
                    <div className={`icon ${openIndex === index ? 'rotate' : ''}`}>
                      <PiPlus />
                    </div>
                  </div>
                  <div
                    className={`answer ${openIndex === index ? 'show' : ''}`}
                    // style={
                    //   openIndex === index
                    //     ? { maxHeight: `${document.getElementById(`faq-${index}`)?.scrollHeight}px` }
                    //     : { maxHeight: 0 }
                    // }
                    id={`faq-${index}`}
                  >
                    <p>{faq.answer}</p>
                   
                  </div>
                </div>
              ))}
            </div>           
          </div>
          <div className='col-lg-2'></div>
        </div>
      </div>
    </section>
  );
};

export default Faqs;
