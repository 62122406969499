import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './component/screens/Home';
import Header from './component/HeaderFooterr/Header';
import Footer from './component/HeaderFooterr/Footer';
import SidebarContactus from './component/HomeComponent/SidebarContactus';
import { useSpring, animated } from '@react-spring/web';
import Services from './component/screens/Services';
import SocialMediaMarketing from './component/screens/SocialMediaMarketing';
import ScrollToTop from './component/ScrollComp/ScrollToTop';
import useScrollRestoration from './component/ScrollComp/useScrollRestoration';
import About from './component/screens/About';
import ContactUs from './component/screens/ContactUs';
import LogoDesign from './component/screens/LogoDesign';
import SeoService from './component/screens/SeoService';
import BrandingService from './component/screens/BrandingService';
import MobileApplication from './component/screens/MobileApplication';
import WebisteDesign from './component/screens/WebisteDesign';
import Portfolio from './component/screens/Portfolio';
import MagneticText from './component/screens/MagneticText';
import gsap from 'gsap';
import SmoothScroll from './SmoothScroll';
import CustomCursor from './CustomCursor';
import ScrollToTopButton from './component/ScrollToTop/ScrollToTopButton';

function App() {

  useScrollRestoration();


  return (
    // <SmoothScroll ref={smoothScrollRef}>
    <div className="App">
      <CustomCursor/>
      <Header />
      <ScrollToTop/>
      <SidebarContactus />
      <Routes>
        <Route path="/" element={<Home  />} />
        <Route path="/all-service" element={<Services  />} />
        <Route path="/logo-design" element={<LogoDesign  />} />
        <Route path="/seo-service" element={<SeoService  />} />
        <Route path="/branding-service" element={<BrandingService  />} />
        <Route path="/mobile-app-service" element={<MobileApplication  />} />
        <Route path="/website-design-service" element={<WebisteDesign  />} />
        <Route path="/smm-service" element={<SocialMediaMarketing  />} />
        <Route path="/about-us" element={<About  />} />
        <Route path="/portfolio" element={<Portfolio  />} />
        <Route path="/contact-us" element={<ContactUs  />} />
        <Route path="/magnetic-text" element={<MagneticText />} />
      </Routes>
      {/* <Footer /> */}
      <ScrollToTopButton />
    </div>
    // </SmoothScroll>
  );
}

export default App;
