import React from "react";
import { TweenLite, Power4 } from "gsap";

export default class SmoothScroll extends React.Component {
  state = {
    contentHeight: 0,
    viewportHeight: window.innerHeight,
    footerHeight: 100, // Add space for the footer
  };

  resizeObserver = new ResizeObserver(() => {
    window.requestAnimationFrame(() => {
      const contentHeight = this.viewport.scrollHeight;
      this.setState({ contentHeight });
    });
  });

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.resizeObserver.observe(this.viewport);
    this.updateContentHeight(); // Ensure initial height is calculated
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    this.resizeObserver.disconnect();
  }

  updateContentHeight = () => {
    const contentHeight = this.viewport.scrollHeight;
    this.setState({ contentHeight });
  };

  handleScroll = () => {
    const { viewportHeight, contentHeight } = this.state;
    const maxScroll = contentHeight - viewportHeight;
    const scrollY = Math.min(window.pageYOffset, maxScroll);

    TweenLite.to(this.viewport, 1, {
      y: -scrollY,
      ease: Power4.easeOut,
    });
  };

  render() {
    const { footerHeight } = this.state;
    return (
      <>
        <div
          className="viewport"
          ref={(ref) => (this.viewport = ref)}
          style={{ position: "fixed", width: "100%", top: 0 }}
        >
          {this.props.children}
        </div>
        <div
          style={{
            height: `${this.state.contentHeight + footerHeight}px`,
          }}
        />
      </>
    );
  }
}
