import React, { useEffect, useRef } from 'react';
import './mag.css'
const MagneticText = () => {
  const textRef = useRef(null);
  const cursorRef = useRef(null);

  useEffect(() => {
    const text = "MAGNETIC";
    const container = textRef.current;
    const cursor = cursorRef.current;

    // Create span elements for each letter
    text.split('').forEach((char) => {
      const span = document.createElement('span');
      span.textContent = char;
      span.classList.add('letter');
      container.appendChild(span);
    });

    const letters = document.querySelectorAll('.letter');
    const animationContainer = container.closest('#animation-container');

    // Function for linear interpolation
    const lerp = (start, end, amount) => (1 - amount) * start + amount * end;

    // Mouse move event listener
    const handleMouseMove = (e) => {
      const mouseX = e.clientX;
      const mouseY = e.clientY;

      letters.forEach((letter) => {
        const rect = letter.getBoundingClientRect();
        const centerX = rect.left + rect.width / 2;
        const centerY = rect.top + rect.height / 2;

        const distanceX = mouseX - centerX;
        const distanceY = mouseY - centerY;
        const distance = Math.sqrt(distanceX ** 2 + distanceY ** 2);
        const maxDistance = 200;

        if (distance < maxDistance) {
          const force = (maxDistance - distance) / maxDistance;
          const moveX = lerp(0, distanceX, force * 0.5);
          const moveY = lerp(0, distanceY, force * 0.5);
          letter.style.transform = `translate(${moveX}px, ${moveY}px)`;
          letter.style.transitionDuration = '0.2s';
        } else {
          letter.style.transform = 'translate(0, 0)';
          letter.style.transitionDuration = '0.5s';
        }
      });

      cursor.style.left = `${mouseX}px`;
      cursor.style.top = `${mouseY}px`;
    };

    // Mouse leave event listener
    const handleMouseLeave = () => {
      letters.forEach((letter) => {
        letter.style.transform = 'translate(0, 0)';
        letter.style.transitionDuration = '0.5s';
      });
    };

    animationContainer.addEventListener('mousemove', handleMouseMove);
    animationContainer.addEventListener('mouseleave', handleMouseLeave);

    // Cleanup event listeners
    return () => {
      animationContainer.removeEventListener('mousemove', handleMouseMove);
      animationContainer.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, []);

  return (
    <div id="animation-container">
      <div id="text-container" ref={textRef}></div>
      <div id="cursor" ref={cursorRef}></div>
      <div id="instruction">Move your cursor over the text</div>
    </div>
  );
};

export default MagneticText;
