import React, { useEffect, useRef, useState } from 'react';
import Main from '../ServicesComponent/SocialMediaMarketing/Main'
import BestChoice from '../ServicesComponent/SocialMediaMarketing/BestChoice';
import StageProcess from '../ServicesComponent/SocialMediaMarketing/StageProcess';
import Faqs from '../ServicesComponent/SocialMediaMarketing/Faqs';
import { Helmet } from 'react-helmet';
import ReadyToStart from '../ServicesComponent/SocialMediaMarketing/ReadyToStart';
import SmoothScroll from '../../SmoothScroll';
import Footer from '../HeaderFooterr/Footer';

const SocialMediaMarketing = () => {
    const [offsetY, setOffsetY] = useState(0);

  const handleScroll = () => {
    setOffsetY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const smoothScrollRef = useRef(null);

  return (
    <>
    <Helmet>
        <title>Social Media Marketing Services In the UK</title>
        <meta
          name="description"
          content="Expert social media marketing services in the UK! Our agency offers tailored social media consulting, content creation, and management solutions."
        />
      </Helmet>
      <SmoothScroll ref={smoothScrollRef}>
    <div
        className="services-container"
        style={{
          backgroundPositionY: `${offsetY * 0.5}px`, // Adjust this multiplier to control the speed
        }}
      >
      <Main/>
      <BestChoice/>
      <ReadyToStart/>
      <StageProcess/>
      <Faqs/>
      </div>
      <Footer className="fade-up" id="scroll-target-6" />
      </SmoothScroll>
    </>
  )
}

export default SocialMediaMarketing
