import React, { useState, useEffect } from 'react';
import { PiPlus } from 'react-icons/pi';
import './faqs.css';

const Faqs = () => {
  const [openIndex, setOpenIndex] = useState(null);
  const [cursorPos, setCursorPos] = useState({ x: 0, y: 0 });

  const toggleFaq = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  // Function to update cursor position on mouse move
  const handleMouseMove = (event) => {
    setCursorPos({
      x: event.clientX,
      y: event.clientY,
    });
  };

  const faqs = [
    {
      id: 1,
      question: "What services do Apex Web Experts provide?",
      answer: "Apex Web Experts specializes in a wide range of digital solutions, including:",
      subanswerslist: [
        "Web Design and Development",
        "E-commerce Website Development",
        "SEO Services (On-Page, Technical, and Local SEO)",
        "Branding and Graphic Design",
        "Digital Marketing (Social Media, Content Marketing, and PPC)",
        "Custom Website Solutions",
      ],
      answer2: "We aim to deliver innovative and tailored strategies to meet the unique needs of your business.",
    },
    {
      id: 2,
      question: "How long does it take to complete a custom website design?",
      answer: "The timeline for completing a custom website depends on the project's size and complexity. On average, a standard website takes 4-6 weeks from initial consultation to final launch. For more advanced features, like e-commerce or custom integrations, the timeline may extend to 8-12 weeks. We ensure timely delivery without compromising quality.",
    },
    {
      id: 3,
      question: "What industries do Apex Web Experts serve?",
      answer: "We work with businesses across various industries, including:",
      subanswerslist: [
        "E-commerce and Retail",
        "Real Estate",
        "Healthcare",
        "Education",
        "Small and Medium Enterprises (SMEs)",
        "Nonprofits",
      ],
      answer2: "No matter your niche, we craft tailored solutions that align with your goals and audience.",
    },
    {
      id: 4,
      question: "Do you offer ongoing website maintenance and support?",
      answer: "Yes! Apex Web Experts provides comprehensive website maintenance and support services. We ensure your site stays secure, up-to-date, and optimized for performance. From content updates to troubleshooting technical issues, our team is here to help your website run smoothly.",
    },
    {
      id: 5,
      question: "Can you improve my website's SEO rankings?",
      answer: "Absolutely! Our SEO specialists use proven strategies to boost your website’s visibility on search engines. We offer on-page SEO services, technical SEO audits, and keyword-optimized content creation to improve rankings, drive organic traffic, and enhance your online presence.",
    },
    {
      id: 6,
      question: "What makes Apex Web Experts different from other web design agencies?",
      answer: "At Apex Web Experts, we prioritize customized solutions and client satisfaction. Our team blends creativity with technical expertise to deliver websites that not only look stunning but also drive measurable results. We take a collaborative approach, ensuring your vision is at the core of every project.",
    }
  ];

  
  return (
    <section className="faq-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
            </div>
           
          </div>
          <div className='col-lg-2'></div>
          <div className="col-lg-8">
          <div className="faq-wrapper">
            {faqs.map((faq, index) => (
                <div key={index} className="faq-item">
                  <div
                    className="d-flex justify-content-between align-items-center question"
                    onClick={() => toggleFaq(index)}
                  >
                    <h4>{faq.question}</h4>
                    <p>
                    
                    </p>
                    <div className={`icon ${openIndex === index ? 'rotate' : ''}`}>
                      <PiPlus />
                    </div>
                  </div>
                  <div
                    className={`answer ${openIndex === index ? 'show' : ''}`}
                    style={
                      openIndex === index
                        ? { maxHeight: `${document.getElementById(`faq-${index}`)?.scrollHeight}px` }
                        : { maxHeight: 0 }
                    }
                    id={`faq-${index}`}
                  >
                    <p>{faq.answer}</p>
                    {faq.subanswerslist && (
                      <ul>
                        {faq.subanswerslist.map((subanswer, subIndex) => (
                          <li key={subIndex}>{subanswer}</li>
                        ))}
                      </ul>
                    )}
                    {faq.answer2 && <p>{faq.answer2}</p>}
                  </div>
                </div>
              ))}
            </div>           
          </div>
          <div className='col-lg-2'></div>
        </div>
      </div>
    </section>
  );
};

export default Faqs;
