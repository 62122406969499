import React, { useEffect, useState } from 'react';
import { BsInstagram } from 'react-icons/bs';
import { CgWebsite } from 'react-icons/cg';
import { DiAndroid } from 'react-icons/di';
import { HiMiniArrowSmallRight } from 'react-icons/hi2';
import { MdBrandingWatermark, MdWeb } from 'react-icons/md';
import { PiPlus } from 'react-icons/pi';
import { SiAdobeindesign } from 'react-icons/si';
import { TbSeo, TbSocial } from 'react-icons/tb';
import { Link } from 'react-router-dom';


const Main = () => {
    const [openIndex, setOpenIndex] = useState(null);
    const [openIndex1, setOpenIndex1] = useState(null);
    const [cursorPos, setCursorPos] = useState({ x: 0, y: 0 });
  
    const toggleFaq = (index) => {
      setOpenIndex(openIndex === index ? null : index);
    };
    const toggleFaq1 = (index1) => {
      setOpenIndex1(openIndex1 === index1 ? null : index1);
    };
  
    // Function to update cursor position on mouse move
    const handleMouseMove = (event) => {
      setCursorPos({
        x: event.clientX,
        y: event.clientY,
      });
    };
  
    const faqs = [
      {
        question: "01. Reach the Right Audience  ",
        answer: "Our expert targeting ensures your content reaches the people who matter most to your business, whether you're in Sydney, Melbourne, or anywhere in Australia.  ",
      },
      {
        question: "02. Save Time and Resources  ",
        answer: "Managing social media can be time-consuming. Let Apex Web Experts handle your campaigns while you focus on running your business.",
      },
      {
        question: "03. Increase Engagement and Conversations  ",
        answer: "With our tailored strategies and engaging content, we help you boost audience interaction and turn followers into customers.  ",
      },
      {
        question: "04. Stay Ahead of the Competition  ",
        answer: "As a top social media marketing agency Australia, we stay on top of the latest trends to ensure your brand remains relevant and competitive.  ",
      },
      
    ];
  

  return (
    <section className="hero container">
      <div className="mainHeading">
        <h2>
        Social Media Marketing Agency in Australia 
        </h2>
        <h3 className='text-white'>
        Dominate the Digital Landscape with a Leading Social Media Marketing Agency in Australia 
        </h3>
        <p>
        In today’s fast-paced digital world, businesses need a strong presence on social media to thrive. At <b>Apex Web Experts</b>, a top-rated social media marketing agency, we help Australian businesses build connections, engage audiences, and drive measurable results. Whether you're looking for a social media agency Australia, social media marketing Sydney, or social media marketing Melbourne, we offer custom solutions tailored to your goals.
        <br/>
        Our social media marketing services cover everything from strategy development and content creation to paid advertising and analytics, ensuring your business stays ahead of the competition. 
        </p>
        <div className='custom-price-button'>
        <Link to='/contact-us'><button>
          Get Started
        </button></Link>
        </div>
      </div>
      <div className="container contentsec">
        <div className='row'>
            <div className='col-lg-12'>
                    <img src={require('../../assets/smmapexAus.png')} />
            </div>
        </div>
        <div className='row'>
            <div className='col-lg-12'>
                <h2>
                    <span>Benefits</span> of Partnering with a Social Media Marketing Agency in Australia  
                </h2>
                {/* <p>
                As the agency Australia businesses trust for branding, we offer unmatched value to our clients. Here’s how our branding expertise can transform your business:
                </p> */}
            </div>
        </div>
        <div className='row'>
            <div className='col-lg-12'>
            <section className="faq-section">
      <div className="container">
        <div className="row">
          
          {/* <div className='col-lg-2'></div> */}
          <div className="faq-wrapper" >
            {faqs.map((faq, index) => (
                <div  className="faq-item" key={index}>
                  <div
                    className="d-flex justify-content-between align-items-center question"
                    // onClick={() => toggleFaq(index)}
                  >
                    <h4>{faq.question}</h4>
                    {/* <div className={`icon `}>
                      <PiPlus />
                    </div> */}
                    {/* Custom "Open" text following the cursor */}
                    
                  </div>
                  <div
                    // className={`answer ${openIndex === index ? 'show' : ''}`}
                    // style={
                    //   openIndex === index
                    //     ? { maxHeight: `${document.getElementById(`faq-${index}`)?.scrollHeight}px` }
                    //     : { maxHeight: 0 }
                    // }
                    // id={`faq-${index}`}
                  >
                    <p>{faq.answer}</p>
                  </div>
                </div>
              ))}
            </div>          
          
          {/* <div className='col-lg-2'></div> */}
        </div>
      </div>
    </section>
            </div>
        </div>
        
      </div>
    </section>
  );
};

export default Main;
