import React from "react";
import "./packages.css";
import { Tilt } from "react-tilt";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";

const Packages = () => {
	const packages = [
		{
		  id: 1,
		  title: "Standard Package",
		  subtitle: "E-Commerce Basic",
		  price: "$600",
		  description: "per person, per month",
		  features: [
			"E-Commerce website Design",
			"Customize Tailor Made Design",
			"User Friendly",
			"5 Banner Designs",
			"Sliding Banner",
			"5 Stock Images",
			"100% Money Back Guarantee",
			"100% Owner Rights",
		  ],
		},
		{
		  id: 2,
		  title: "Premium Package",
		  subtitle: "E-Commerce Professional",
		  price: "$900",
		  description: "per person, per month",
		  features: [
			"E-Commerce website Design",
			"Customize Tailor Made Design",
			"User Friendly",
			"5 Banner Designs",
			"Sliding Banner",
			"5 Stock Images",
			"100% Money Back Guarantee",
			"100% Owner Rights",
		  ],
		},
		{
		  id: 3,
		  title: "Signature Package",
		  subtitle: "E-Commerce Corporate",
		  price: "$1200",
		  description: "per person, per month",
		  features: [
			"E-Commerce website Design",
			"Customize Tailor Made Design",
			"User Friendly",
			"5 Banner Designs",
			"Sliding Banner",
			"5 Stock Images",
			"100% Money Back Guarantee",
			"100% Owner Rights",
		  ],
		},
	  ];
  return (
    <>
      <section id="price-plans-section">
        <div className="container">
          <div className="row">
            <div className="heading-text" style={{ textalign: "center" }}>
              <h3 style={{ color: "white" }}>Packages </h3>
            </div>

            {/* TABS PRICE START */}
            <ul className="nav nav-tabs d-lg-flex" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="home-tab1"
                  data-bs-toggle="tab"
                  data-bs-target="#home-tab-pane1"
                  type="button"
                  role="tab"
                  aria-controls="home-tab-pane1"
                  aria-selected="true"
                >
                  Websites
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="profile-tab2"
                  data-bs-toggle="tab"
                  data-bs-target="#profile-tab-pane2"
                  type="button"
                  role="tab"
                  aria-controls="profile-tab-pane2"
                  aria-selected="false"
                >
                  Logos
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="profile-tab3"
                  data-bs-toggle="tab"
                  data-bs-target="#profile-tab-pane3"
                  type="button"
                  role="tab"
                  aria-controls="profile-tab-pane3"
                  aria-selected="false"
                >
                  Branding
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="profile-tab4"
                  data-bs-toggle="tab"
                  data-bs-target="#profile-tab-pane4"
                  type="button"
                  role="tab"
                  aria-controls="#profile-tab-pane4"
                  aria-selected="false"
                >
                  Social Media Marketing
                </button>
              </li>

              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="profile-tab5"
                  data-bs-toggle="tab"
                  data-bs-target="#profile-tab-pane5"
                  type="button"
                  role="tab"
                  aria-controls="#profile-tab-pane5"
                  aria-selected="false"
                >
                  Mobile App Design
                </button>
              </li>
            </ul>
            {/* TABS PRICE END */}

            {/* WEBSITE PRICE PLAN SECTION */}
            <div className="tab-content accordion ghm" id="myTabContent">
              <div
                className="tab-pane fade show active accordion-item"
                id="home-tab-pane1"
                role="tabpanel"
                aria-labelledby="home-tab1"
                tabindex="0"
              >
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show  d-lg-block"
                  aria-labelledby="headingOne"
                  data-bs-parent="#myTabContent"
                >
                  <div className="container">
                    <div className="row">
					<Swiper
        navigation={true}
        pagination={{ clickable: true }}
        modules={[Navigation, Pagination]}
        className="mySwiper"
		  // modules={[Autoplay]}
		  spaceBetween={30}
		  slidesPerView={3}
		  autoplay={{ delay: 3000 }}
		  breakpoints={{
			// Responsive breakpoints
			1024: {
			  slidesPerView: 3,
			},
			768: {
			  slidesPerView: 2,
			},
			480: {
			  slidesPerView: 1,
			  },
			576: {
			  slidesPerView: 1,
			},
			0: {
			  slidesPerView: 1,
			},
		  }}
      >
        {packages.map((pkg) => (
          <SwiperSlide key={pkg.id}>
            <div className="py-4 price-inner-plan">
              <h2 className="heading-pricing-colors">{pkg.title}</h2>
              <h3>{pkg.subtitle}</h3>
              <div className="price-heading-text">
                <h4>{pkg.price}</h4>
                <p className="text-sm font-light">{pkg.description}</p>
              </div>
              <div className="price-list">
                <ul>
                  {pkg.features.map((feature, index) => (
                    <li key={index}>{feature}</li>
                  ))}
                </ul>
              </div>
             
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
                      <div
                        className="custom-price-button"
                        style={{ paddingTop: "70px" }}
                      >
                        <Link to="/contact-us">
                          <button>Get Started</button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* WEBSITE PRICE SECTION END */}

            {/* LOGOS PRICE PLAN Start */}
            <div
              className="tab-pane fade accordion-item prr"
              id="profile-tab-pane2"
              role="tabpanel"
              aria-labelledby="profile-tab2"
              tabindex="0"
            >
              <div
                id="collapseTwo"
                className="accordion-collapse collapse d-lg-block"
                aria-labelledby="headingTwo"
                data-bs-parent="#myTabContent"
              >
                <div className="container">
                  <div className="row">
				  <Swiper
        navigation={true}
        pagination={{ clickable: true }}
        modules={[Navigation, Pagination]}
        className="mySwiper"
		  // modules={[Autoplay]}
		  spaceBetween={30}
		  slidesPerView={3}
		  autoplay={{ delay: 3000 }}
		  breakpoints={{
			// Responsive breakpoints
			1024: {
			  slidesPerView: 3,
			},
			768: {
			  slidesPerView: 2,
			},
			480: {
			  slidesPerView: 1,
			  },
			576: {
			  slidesPerView: 1,
			},
			0: {
			  slidesPerView: 1,
			},
		  }}
      >
		 <SwiperSlide>
                      <div className="py-4 price-inner-plan">
                        <h2 className="heading-pricing-colors">
                          Standard Package
                        </h2>
                        <h3>Logos Basic</h3>
                        <div className="price-heading-text">
                          <h4>$600</h4>
                          <p class="text-sm font-light">
                            per person, per month
                          </p>
                        </div>
                        <div className="price-list">
                          <ul>
                            <li>E-Commerce website Design</li>
                            <li>Customize Tailor Made Design</li>
                            <li>User Friendly</li>
                            <li>5 Banner Designs</li>
                            <li>Sliding Banner </li>
                            <li>5 Stock Images </li>
                            <li>100% Money Back Gurantee</li>
                            <li>100% Owner Rights</li>
                            <li>E-Commerce website Design</li>
                            <li>Customize Tailor Made Design</li>
                            <li>User Friendly</li>
                            <li>5 Banner Designs</li>
                          </ul>
                        </div>
                      </div>
					  </SwiperSlide>
					  <SwiperSlide>
					  <div className="py-4 price-inner-plan">
                        <h2 className="heading-pricing-colors">
                          Premium Package
                        </h2>
                        <h3>Logos Professional</h3>
                        <div className="price-heading-text">
                          <h4>$900</h4>
                          <p class="text-sm font-light">
                            per person, per month
                          </p>
                        </div>
                        <div className="price-list">
                          <ul>
                            <li>E-Commerce website Design</li>
                            <li>Customize Tailor Made Design</li>
                            <li>User Friendly</li>
                            <li>5 Banner Designs</li>
                            <li>Sliding Banner </li>
                            <li>5 Stock Images </li>
                            <li>100% Money Back Gurantee</li>
                            <li>100% Owner Rights</li>
                            <li>E-Commerce website Design</li>
                            <li>Customize Tailor Made Design</li>
                            <li>User Friendly</li>
                            <li>5 Banner Designs</li>
                          </ul>
                        </div>
                      </div>
					  </SwiperSlide>
					  <SwiperSlide>
					  <div className="py-4 price-inner-plan">
                        <h2 className="heading-pricing-colors">
                          Signature Package
                        </h2>
                        <h3>Logos Corporate</h3>
                        <div className="price-heading-text">
                          <h4>$1200</h4>
                          <p class="text-sm font-light">
                            per person, per month
                          </p>
                        </div>
                        <div className="price-list">
                          <ul>
                            <li>E-Commerce website Design</li>
                            <li>Customize Tailor Made Design</li>
                            <li>User Friendly</li>
                            <li>5 Banner Designs</li>
                            <li>Sliding Banner </li>
                            <li>5 Stock Images </li>
                            <li>100% Money Back Gurantee</li>
                            <li>100% Owner Rights</li>
                            <li>E-Commerce website Design</li>
                            <li>Customize Tailor Made Design</li>
                            <li>User Friendly</li>
                            <li>5 Banner Designs</li>
                          </ul>
                        </div>
                      </div>
					  </SwiperSlide>
					  
					  </Swiper>

                    

                    <div
                      className="custom-price-button"
                      style={{ paddingTop: "70px" }}
                    >
                      <Link to="/contact-us">
                        <button>Get Started</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* LOGOS PRICE PLAN END */}

            {/* BRANDING PRICE PLAN Start */}
            <div
              className="tab-pane fade accordion-item prr"
              id="profile-tab-pane3"
              role="tabpanel"
              aria-labelledby="profile-tab3"
              tabindex="0"
            >
              <div
                id="collapseTwo"
                className="accordion-collapse collapse d-lg-block"
                aria-labelledby="headingTwo"
                data-bs-parent="#myTabContent"
              >
                <div className="container">
                  <div className="row">
				  <Swiper
        navigation={true}
        pagination={{ clickable: true }}
        modules={[Navigation, Pagination]}
        className="mySwiper"
		  // modules={[Autoplay]}
		  spaceBetween={30}
		  slidesPerView={3}
		  autoplay={{ delay: 3000 }}
		  breakpoints={{
			// Responsive breakpoints
			1024: {
			  slidesPerView: 3,
			},
			768: {
			  slidesPerView: 2,
			},
			480: {
			  slidesPerView: 1,
			  },
			576: {
			  slidesPerView: 1,
			},
			0: {
			  slidesPerView: 1,
			},
		  }}
      >
                    <SwiperSlide>
                      <div className="py-4 price-inner-plan">
                        <h2 className="heading-pricing-colors">
                          Standard Package
                        </h2>
                        <h3>Branding Basic</h3>
                        <div className="price-heading-text">
                          <h4>$600</h4>
                          <p class="text-sm font-light">
                            per person, per month
                          </p>
                        </div>
                        <div className="price-list">
                          <ul>
                            <li>E-Commerce website Design</li>
                            <li>Customize Tailor Made Design</li>
                            <li>User Friendly</li>
                            <li>5 Banner Designs</li>
                            <li>Sliding Banner </li>
                            <li>5 Stock Images </li>
                            <li>100% Money Back Gurantee</li>
                            <li>100% Owner Rights</li>
                            <li>E-Commerce website Design</li>
                            <li>Customize Tailor Made Design</li>
                            <li>User Friendly</li>
                            <li>5 Banner Designs</li>
                          </ul>
                        </div>
                      </div>
					  </SwiperSlide>

					  <SwiperSlide>
                      <div className="py-4 price-inner-plan">
                        <h2 className="heading-pricing-colors">
                          Premium Package
                        </h2>
                        <h3>Branding Professional</h3>
                        <div className="price-heading-text">
                          <h4>$900</h4>
                          <p class="text-sm font-light">
                            per person, per month
                          </p>
                        </div>
                        <div className="price-list">
                          <ul>
                            <li>E-Commerce website Design</li>
                            <li>Customize Tailor Made Design</li>
                            <li>User Friendly</li>
                            <li>5 Banner Designs</li>
                            <li>Sliding Banner </li>
                            <li>5 Stock Images </li>
                            <li>100% Money Back Gurantee</li>
                            <li>100% Owner Rights</li>
                            <li>E-Commerce website Design</li>
                            <li>Customize Tailor Made Design</li>
                            <li>User Friendly</li>
                            <li>5 Banner Designs</li>
                          </ul>
                        </div>
                      </div>
                    </SwiperSlide>

                    <SwiperSlide>
                      <div className="py-4 price-inner-plan">
                        <h2 className="heading-pricing-colors">
                          Signature Package
                        </h2>
                        <h3>Branding Corporate</h3>
                        <div className="price-heading-text">
                          <h4>$1200</h4>
                          <p class="text-sm font-light">
                            per person, per month
                          </p>
                        </div>
                        <div className="price-list">
                          <ul>
                            <li>E-Commerce website Design</li>
                            <li>Customize Tailor Made Design</li>
                            <li>User Friendly</li>
                            <li>5 Banner Designs</li>
                            <li>Sliding Banner </li>
                            <li>5 Stock Images </li>
                            <li>100% Money Back Gurantee</li>
                            <li>100% Owner Rights</li>
                            <li>E-Commerce website Design</li>
                            <li>Customize Tailor Made Design</li>
                            <li>User Friendly</li>
                            <li>5 Banner Designs</li>
                          </ul>
                        </div>
                      </div>
                    </SwiperSlide>
		  	</Swiper>
                    <div
                      className="custom-price-button"
                      style={{ paddingTop: "70px" }}
                    >
                      <Link to="/contact-us">
                        <button>Get Started</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* BRANDING PRICE PLAN END  */}

            {/* Social Media Marketing PRICE PLAN Start */}
            <div
              className="tab-pane fade accordion-item prr"
              id="profile-tab-pane4"
              role="tabpanel"
              aria-labelledby="profile-tab4"
              tabindex="0"
            >
              <div
                id="collapseTwo"
                className="accordion-collapse collapse d-lg-block"
                aria-labelledby="headingTwo"
                data-bs-parent="#myTabContent"
              >
                <div className="container">
                  <div className="row">
				  <Swiper
        navigation={true}
        pagination={{ clickable: true }}
        modules={[Navigation, Pagination]}
        className="mySwiper"
		  // modules={[Autoplay]}
		  spaceBetween={30}
		  slidesPerView={3}
		  autoplay={{ delay: 3000 }}
		  breakpoints={{
			// Responsive breakpoints
			1024: {
			  slidesPerView: 3,
			},
			768: {
			  slidesPerView: 2,
			},
			480: {
			  slidesPerView: 1,
			  },
			576: {
			  slidesPerView: 1,
			},
			0: {
			  slidesPerView: 1,
			},
		  }}
      >
                    <SwiperSlide>
                      <div className="py-4 price-inner-plan">
                        <h2 className="heading-pricing-colors">
                          Standard Package
                        </h2>
                        <h3>Social Media Marketing Basic</h3>
                        <div className="price-heading-text">
                          <h4>$600</h4>
                          <p class="text-sm font-light">
                            per person, per month
                          </p>
                        </div>
                        <div className="price-list">
                          <ul>
                            <li>E-Commerce website Design</li>
                            <li>Customize Tailor Made Design</li>
                            <li>User Friendly</li>
                            <li>5 Banner Designs</li>
                            <li>Sliding Banner </li>
                            <li>5 Stock Images </li>
                            <li>100% Money Back Gurantee</li>
                            <li>100% Owner Rights</li>
                            <li>E-Commerce website Design</li>
                            <li>Customize Tailor Made Design</li>
                            <li>User Friendly</li>
                            <li>5 Banner Designs</li>
                          </ul>
                        </div>
                      </div>
                    </SwiperSlide>

                    <SwiperSlide>
                      <div className="py-4 price-inner-plan">
                        <h2 className="heading-pricing-colors">
                          Premium Package
                        </h2>
                        <h3>Social Media Marketing Professional</h3>
                        <div className="price-heading-text">
                          <h4>$900</h4>
                          <p class="text-sm font-light">
                            per person, per month
                          </p>
                        </div>
                        <div className="price-list">
                          <ul>
                            <li>E-Commerce website Design</li>
                            <li>Customize Tailor Made Design</li>
                            <li>User Friendly</li>
                            <li>5 Banner Designs</li>
                            <li>Sliding Banner </li>
                            <li>5 Stock Images </li>
                            <li>100% Money Back Gurantee</li>
                            <li>100% Owner Rights</li>
                            <li>E-Commerce website Design</li>
                            <li>Customize Tailor Made Design</li>
                            <li>User Friendly</li>
                            <li>5 Banner Designs</li>
                          </ul>
                        </div>
                      </div>
                    </SwiperSlide>

                    <SwiperSlide>
                      <div className="py-4 price-inner-plan">
                        <h2 className="heading-pricing-colors">
                          Signature Package
                        </h2>
                        <h3>Social Media Marketing Corporate</h3>
                        <div className="price-heading-text">
                          <h4>$1200</h4>
                          <p class="text-sm font-light">
                            per person, per month
                          </p>
                        </div>
                        <div className="price-list">
                          <ul>
                            <li>E-Commerce website Design</li>
                            <li>Customize Tailor Made Design</li>
                            <li>User Friendly</li>
                            <li>5 Banner Designs</li>
                            <li>Sliding Banner </li>
                            <li>5 Stock Images </li>
                            <li>100% Money Back Gurantee</li>
                            <li>100% Owner Rights</li>
                            <li>E-Commerce website Design</li>
                            <li>Customize Tailor Made Design</li>
                            <li>User Friendly</li>
                            <li>5 Banner Designs</li>
                          </ul>
                        </div>
                      </div>
                    </SwiperSlide>
		  		</Swiper>
                    <div
                      className="custom-price-button"
                      style={{ paddingTop: "70px" }}
                    >
                      <Link to="/contact-us">
                        <button>Get Started</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade accordion-item prr"
              id="profile-tab-pane5"
              role="tabpanel"
              aria-labelledby="profile-tab5"
              tabindex="0"
            >
              <div
                id="collapseTwo"
                className="accordion-collapse collapse d-lg-block"
                aria-labelledby="headingTwo"
                data-bs-parent="#myTabContent"
              >
                <div className="container">
                  <div className="row">
				  <Swiper
        navigation={true}
        pagination={{ clickable: true }}
        modules={[Navigation, Pagination]}
        className="mySwiper"
		  // modules={[Autoplay]}
		  spaceBetween={30}
		  slidesPerView={3}
		  autoplay={{ delay: 3000 }}
		  breakpoints={{
			// Responsive breakpoints
			1024: {
			  slidesPerView: 3,
			},
			768: {
			  slidesPerView: 2,
			},
			480: {
			  slidesPerView: 1,
			  },
			576: {
			  slidesPerView: 1,
			},
			0: {
			  slidesPerView: 1,
			},
		  }}
      >
                    <SwiperSlide>
                      <div className="py-4 price-inner-plan">
                        <h2 className="heading-pricing-colors">
                          Standard Package
                        </h2>
                        <h3>Mobile App Design Basic</h3>
                        <div className="price-heading-text">
                          <h4>$600</h4>
                          <p class="text-sm font-light">
                            per person, per month
                          </p>
                        </div>
                        <div className="price-list">
                          <ul>
                            <li>E-Commerce website Design</li>
                            <li>Customize Tailor Made Design</li>
                            <li>User Friendly</li>
                            <li>5 Banner Designs</li>
                            <li>Sliding Banner </li>
                            <li>5 Stock Images </li>
                            <li>100% Money Back Gurantee</li>
                            <li>100% Owner Rights</li>
                            <li>E-Commerce website Design</li>
                            <li>Customize Tailor Made Design</li>
                            <li>User Friendly</li>
                            <li>5 Banner Designs</li>
                          </ul>
                        </div>
                      </div>
                    </SwiperSlide>

                    <SwiperSlide>
                      <div className="py-4 price-inner-plan">
                        <h2 className="heading-pricing-colors">
                          Premium Package
                        </h2>
                        <h3>Mobile App Design Professional</h3>
                        <div className="price-heading-text">
                          <h4>$900</h4>
                          <p class="text-sm font-light">
                            per person, per month
                          </p>
                        </div>
                        <div className="price-list">
                          <ul>
                            <li>E-Commerce website Design</li>
                            <li>Customize Tailor Made Design</li>
                            <li>User Friendly</li>
                            <li>5 Banner Designs</li>
                            <li>Sliding Banner </li>
                            <li>5 Stock Images </li>
                            <li>100% Money Back Gurantee</li>
                            <li>100% Owner Rights</li>
                            <li>E-Commerce website Design</li>
                            <li>Customize Tailor Made Design</li>
                            <li>User Friendly</li>
                            <li>5 Banner Designs</li>
                          </ul>
                        </div>
                      </div>
                    </SwiperSlide>

                    <SwiperSlide>
                      <div className="py-4 price-inner-plan">
                        <h2 className="heading-pricing-colors">
                          Signature Package
                        </h2>
                        <h3>Mobile App Design Corporate</h3>
                        <div className="price-heading-text">
                          <h4>$1200</h4>
                          <p class="text-sm font-light">
                            per person, per month
                          </p>
                        </div>
                        <div className="price-list">
                          <ul>
                            <li>E-Commerce website Design</li>
                            <li>Customize Tailor Made Design</li>
                            <li>User Friendly</li>
                            <li>5 Banner Designs</li>
                            <li>Sliding Banner </li>
                            <li>5 Stock Images </li>
                            <li>100% Money Back Gurantee</li>
                            <li>100% Owner Rights</li>
                            <li>E-Commerce website Design</li>
                            <li>Customize Tailor Made Design</li>
                            <li>User Friendly</li>
                            <li>5 Banner Designs</li>
                          </ul>
                        </div>
                      </div>
                    </SwiperSlide>
		  		</Swiper>
                    <div
                      className="custom-price-button"
                      style={{ paddingTop: "70px" }}
                    >
                      <Link to="/contact-us">
                        <button>Get Started</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Packages;
