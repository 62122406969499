import React from 'react'

const { useState, useEffect } = React;

const slideArr = [
  {
    id: 1,
    src: require('../assets/aboutGallery1.png'),
    text: {
      header: "Header",
      sub: "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
    }
  },
  {
    id: 2,
    src: require('../assets/aboutGallery2.png'),
    text: {
      header: "Header",
      sub: "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
    }
  },
  {
    id: 3,
    src: require('../assets/aboutGallery3.png'),
    text: {
      header: "Header",
      sub: "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
    }
  },
  {
    id: 4,
    src: require('../assets/aboutGallery4.png'),
    text: {
      header: "Header",
      sub: "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
    }
  },
  {
    id: 5,
    src: require('../assets/aboutGallery5.png'),
    text: {
      header: "Header",
      sub: "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
    }
  },
  {
    id: 6,
    src: require('../assets/aboutGallery6.png'),
    text: {
      header: "Header",
      sub: "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
    }
  }
];

// single image componnet
const ImageSlide = ({ slide, updateActive, activeImg }) => {
  return (
    <div
      style={{
        transition: "0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95)"
      }}
      onClick={() => updateActive(slide.id)}
      className={`${
        activeImg === slide?.id
          ? "w-4/5 lg:w-[500px] grow-1 animate-slightFade"
          : " w-4 md:w-16 lg:w-28 flex-none cursor-pointer"
      } h-80  relative rounded-2xl overflow-hidden bg-gray-200`}
    >
      <img
        className="h-full w-full object-cover"
        src={slide.src}
        alt="about slide"
      />

      {slide?.text && activeImg === slide?.id ? (
        <div className="animate-fadeIn absolute bottom-[10%] text-white p-4">
          <h4 className="font-bold text-xl lg:text-2xl text-white">
            {slide?.text?.header}
          </h4>
          <p className="text-xs text-white">{slide?.text?.sub}</p>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

// slide image component
const GallerySlider = ({ array }) => {
  const [activeImg, setActiveImg] = useState(1);

  useEffect(() => {
    if (activeImg > array?.length) {
      setActiveImg(1);
    }
    const interval = setInterval(() => {
      setActiveImg(activeImg + 1);
    }, 6000);
    return () => clearInterval(interval);
  }, [activeImg, array?.length]);

  const updateActive = (num) => {
    setActiveImg(num);
  };

  return (
    <div className="max-w-[1000px] mx-auto md:h-[410px] flex gap-1 md:gap-2 mt-8 ">
      {array?.map((slide) => (
        <ImageSlide
          key={slide.id}
          slide={slide}
          updateActive={updateActive}
          activeImg={activeImg}
          alt="Image slide"
        />
      ))}
    </div>
  );
};

const OurAgencySnaps = () => {
  return (
    <>
      <section className="meet-our-team">
        <div className="container">
            <div className="row align-items-center">
                <div className='textHead'>
                <h2>
                    <span>Gallery </span>
                    Our Agency Snaps
                </h2>
                <p>
                From team outings and office shenanigans to special events and celebrations, these photos capture the essence of our vibrant culture and the bonds that unite us.
                </p>

                </div>
                <div className="col-lg-12">
                  <div className='gallery'>
                  <GallerySlider array={slideArr} />
                  </div>
                </div>
            </div>
        </div>
      </section>
    </>
  )
}

export default OurAgencySnaps
