import React, { useEffect, useRef, useState } from 'react'
import HoverImageComponent from '../PortfolioComponents/HoverImageComponent';
import { Link } from 'react-router-dom';
import logo1 from '../assets/logo1.svg';
import logo2 from '../assets/logo2.svg';
import logo3 from '../assets/logo3.svg';
import logo4 from '../assets/logo4.svg';
import logo5 from '../assets/logo5.svg';
import logo6 from '../assets/logo6.svg';
import logo7 from '../assets/logo7.svg';
import logo8 from '../assets/logo8.svg';
import logo9 from '../assets/logo9.svg';
import logo10 from '../assets/logo10.svg';
import firstsite from '../assets/firstsite.svg';
import secondsite from '../assets/secondsite.svg';
import thirdsite from '../assets/thirdsite.svg';
import fourthSite from '../assets/fourthSite.svg';
import fifthsite from '../assets/fifthsite.svg';
import stationary1 from '../assets/stationary1.svg';
import stationary2 from '../assets/stationary2.svg';
import stationary3 from '../assets/stationary3.svg';
import stationary4 from '../assets/stationary4.svg';
import stationary5 from '../assets/stationary5.svg';
import brand1 from '../assets/brand1.svg';
import brand2 from '../assets/brand2.svg';
import brand3 from '../assets/brand3.svg';
import brand4 from '../assets/brand4.svg';
import brand5 from '../assets/brand5.svg';
import mobile1 from '../assets/mobile1.svg';
import mobile2 from '../assets/mobile2.svg';
import mobile3 from '../assets/mobile3.svg';
import mobile4 from '../assets/moblie4.svg';
import mobile5 from '../assets/mobile5.svg';

const OurPortfolio = ({ openPopup }) => {
  const imageSets = [
    [
      logo1,
      logo2,
      logo3,
      logo4,
      logo5,
      logo6,
      logo7,
      logo8,
      logo9,
      logo10,
    ],
    [logo2],
    [logo3],
    [logo4],
    [logo5],
  ];
  const imageSetsWebsite = [
    [
      firstsite,
      // require("../assets/website2.png"),
      // require("../assets/website3.png"),
      // require("../assets/website4.png"),
      // require("../assets/website5.png"),
    ],
    [secondsite],
    [thirdsite],
    [fourthSite],
    [fifthsite],
  ];
  const imageSetsStationary = [
    [
      stationary1,
      stationary2,
      stationary3,
      stationary4,
      stationary5,
    ],
    [stationary2],
    [stationary3],
    [stationary4],
    [stationary5],
  ];
  const imageSetsBrand = [
    [
      brand1,
      brand2,
      brand3,
      brand4,
      brand5,
    ],
    [brand2],
    [brand3],
    [brand4],
    [brand5],
  ];
  const imageSetsMobile = [
    [
      mobile1,
      mobile2,
      mobile3,
      mobile4,
      mobile5,
    ],
    [mobile1],
    [mobile2],
    [mobile3],
    [mobile5],
  ];
 // Create an array of refs for each image
 const scrollSmoothly = (targetElement) => {
  const targetPosition = targetElement.getBoundingClientRect().top + window.scrollY;
  const startPosition = window.scrollY;
  const distance = targetPosition - startPosition;
  const duration = 2000; // Adjust duration (in ms) for slower scrolling
  let startTime = null;

  const scrollAnimation = (currentTime) => {
    if (!startTime) startTime = currentTime;
    const timeElapsed = currentTime - startTime;
    const run = easeInOutQuad(timeElapsed, startPosition, distance, duration);
    window.scrollTo(0, run);
    if (timeElapsed < duration) {
      requestAnimationFrame(scrollAnimation);
    }
  };

  requestAnimationFrame(scrollAnimation);
};

const easeInOutQuad = (t, b, c, d) => {
  t /= d / 2;
  if (t < 1) return (c / 2) * t * t + b;
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
};

const scrollTargets = useRef([]);

const handleHover = (index) => {
  const targetElement = scrollTargets.current[index]?.current;
  if (targetElement) {
    scrollSmoothly(targetElement);
  }
};


  
  return (
    <>
      <section
        className="teals-testimonials teals-testimonials-new-sec"
        id="price-plans-section"
      >
        <div className="container shadow">
          <div className="row align-items-center">
            <div className="col-lg-4">
              <div className="testimonial-title text-box">
                <h3>Best of Your work</h3>
                <h2>Latest Project</h2>
              </div>
            </div>
            <div className="col-lg-8">
              <ul className="nav nav-tabs d-lg-flex" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="home-tab09"
                    data-bs-toggle="tab"
                    data-bs-target="#home-tab-pane09"
                    type="button"
                    role="tab"
                    aria-controls="home-tab-pane09"
                    aria-selected="true"
                  >
                    Logo Designs
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="profile-tab08"
                    data-bs-toggle="tab"
                    data-bs-target="#profile-tab-pane08"
                    type="button"
                    role="tab"
                    aria-controls="profile-tab-pane08"
                    aria-selected="false"
                  >
                    Websites
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="profile-tab07"
                    data-bs-toggle="tab"
                    data-bs-target="#profile-tab-pane07"
                    type="button"
                    role="tab"
                    aria-controls="profile-tab-pane07"
                    aria-selected="false"
                  >
                    Stationary
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="profile-tab06"
                    data-bs-toggle="tab"
                    data-bs-target="#profile-tab-pane06"
                    type="button"
                    role="tab"
                    aria-controls="#profile-tab-pane06"
                    aria-selected="false"
                  >
                    Branding
                  </button>
                </li>

                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="profile-tab05"
                    data-bs-toggle="tab"
                    data-bs-target="#profile-tab-pane05"
                    type="button"
                    role="tab"
                    aria-controls="#profile-tab-pane05"
                    aria-selected="false"
                  >
                    Mobile Apps
                  </button>
                </li>
              </ul>
            </div>
            <div className="col-lg-12">
              {/* WEBSITE PRICE PLAN SECTION */}
              <div className="tab-content accordion ghm" id="myTabContent">
                <div
                  className="tab-pane fade show active accordion-item"
                  id="home-tab-pane09"
                  role="tabpanel"
                  aria-labelledby="home-tab09"
                  tabindex="0"
                >
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show  d-lg-block"
                    aria-labelledby="headingOne"
                    data-bs-parent="#myTabContent"
                  >
                    <div className="container">
                      <div className="row gallery align-items-center">
                        <div className="col-sm-12 col-lg-6 mt-5 col-12">
                        <div onClick={() => openPopup(imageSets[0], 0)}>
                        <HoverImageComponent images={imageSets[0]} />
                        </div>
                        </div>

                        <div className="col-sm-12 col-lg-6 mt-5 col-12">
                          <div className="row" style={{ gap: "20px 0px" }}>
                          <div className="col-lg-6 col-6">
                          <div onClick={() => openPopup(imageSets[0], 0)}>
                <HoverImageComponent images={imageSets[1]} />
              </div>
              </div>
                            <div className="col-lg-6 col-6">
                            <div onClick={() => openPopup(imageSets[0], 0)}>
                <HoverImageComponent images={imageSets[2]} />
              </div>
                            </div>
                            <div className="col-lg-6 col-6">
                            <div onClick={() => openPopup(imageSets[0], 0)}>
                            <HoverImageComponent images={imageSets[3]} />
                            </div>
                            </div>
                            
                            <div className="col-lg-6 col-6">
                            <div onClick={() => openPopup(imageSets[0], 0)}>
                            <HoverImageComponent images={imageSets[4]} />
                            </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="custom-price-button"
                          style={{ paddingTop: "70px" }}
                        >
                         <Link to ="/contact-us"><button>Get Started</button></Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* WEBSITE PRICE SECTION END */}
 {/* Popup Modal */}

              {/* LOGOS PRICE PLAN Start */}
              <div
                className="tab-pane fade accordion-item prr"
                id="profile-tab-pane08"
                role="tabpanel"
                aria-labelledby="profile-tab08"
                tabindex="0"
              >
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse d-lg-block"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#myTabContent"
                >
                    <div className="container">
                      <div className="row gallery align-items-center">
                        <div className="col-sm-12 col-lg-6 mt-5 col-12 mainimage">
                        
                        <div
        className="image-container"
        onMouseEnter={() => handleHover(0)}
        ref={scrollTargets.current[0]}
      >
        <HoverImageComponent images={imageSetsWebsite[0]} />
      </div>
                        </div>

                        <div className="col-sm-12 col-lg-6 mt-5 col-12 galleryimages">
                          <div className="row" style={{ gap: "20px 0px" }}>
                          {imageSetsWebsite.slice(1).map((images, index) => (
          <div
            key={index + 1}
            className="col-lg-6 col-6 image-container"
            onMouseEnter={() => handleHover(index + 1)}
            ref={scrollTargets.current[index + 1]}
          >
            <HoverImageComponent images={images} />
          </div>
        ))}
                          </div>
                        </div>

                        <div
                          className="custom-price-button"
                          style={{ paddingTop: "70px" }}
                        >
                          <Link to ="/contact-us"><button>Get Started</button></Link>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
              {/* LOGOS PRICE PLAN END */}

              {/* BRANDING PRICE PLAN Start */}
              <div
                className="tab-pane fade accordion-item prr"
                id="profile-tab-pane07"
                role="tabpanel"
                aria-labelledby="profile-tab07"
                tabindex="0"
              >
                  <div
                  id="collapseTwo"
                  className="accordion-collapse collapse d-lg-block"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#myTabContent"
                >
                  <div className="container">
                      <div className="row gallery align-items-center">
                        <div className="col-sm-12 col-lg-6 mt-5 col-12">
                        <div onClick={() => openPopup(imageSetsStationary[0], 0)}>
                <HoverImageComponent images={imageSetsStationary[0]} />
              </div>
                        </div>

                        <div className="col-sm-12 col-lg-6 mt-5 col-12">
                          <div className="row" style={{ gap: "20px 0px" }}>
                            <div className="col-lg-6 col-6">
                            <div onClick={() => openPopup(imageSetsStationary[1], 0)}>
                <HoverImageComponent images={imageSetsStationary[1]} />
              </div>
                            </div>
                            <div className="col-lg-6 col-6">
                            <div onClick={() => openPopup(imageSetsStationary[2], 0)}>
                <HoverImageComponent images={imageSetsStationary[2]} />
              </div>
                            </div>
                            <div className="col-lg-6 col-6">
                            <div onClick={() => openPopup(imageSetsStationary[3], 0)}>
                <HoverImageComponent images={imageSetsStationary[3]} />
              </div>
                            </div>
                            <div className="col-lg-6 col-6">
                            <div onClick={() => openPopup(imageSetsStationary[4], 0)}>
                <HoverImageComponent images={imageSetsStationary[4]} />
              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="custom-price-button"
                          style={{ paddingTop: "70px" }}
                        >
                          <Link to ="/contact-us"><button>Get Started</button></Link>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
              {/* BRANDING PRICE PLAN END  */}

              {/* Social Media Marketing PRICE PLAN Start */}
              <div
                className="tab-pane fade accordion-item prr"
                id="profile-tab-pane06"
                role="tabpanel"
                aria-labelledby="profile-tab06"
                tabindex="0"
              >
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse d-lg-block"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#myTabContent"
                >
                  <div className="container">
                      <div className="row gallery align-items-center">
                        <div className="col-sm-12 col-lg-6 mt-5 col-12">
                        <div onClick={() => openPopup(imageSetsBrand[0], 0)}>
                <HoverImageComponent images={imageSetsBrand[0]} />
              </div>
                        </div>

                        <div className="col-sm-12 col-lg-6 mt-5 col-12">
                          <div className="row" style={{ gap: "20px 0px" }}>
                            <div className="col-lg-6 col-6">
                            <div onClick={() => openPopup(imageSetsBrand[0], 0)}>
                <HoverImageComponent images={imageSetsBrand[1]} />
              </div>
                            </div>
                            <div className="col-lg-6 col-6">
                            <div onClick={() => openPopup(imageSetsBrand[0], 0)}>
                <HoverImageComponent images={imageSetsBrand[2]} />
              </div>
                            </div>
                            <div className="col-lg-6 col-6">
                            <div onClick={() => openPopup(imageSetsBrand[0], 0)}>
                <HoverImageComponent images={imageSetsBrand[3]} />
              </div>
                            </div>
                            <div className="col-lg-6 col-6">
                            <div onClick={() => openPopup(imageSetsBrand[0], 0)}>
                <HoverImageComponent images={imageSetsBrand[4]} />
              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="custom-price-button"
                          style={{ paddingTop: "70px" }}
                        >
                         <Link to ="/contact-us"><button>Get Started</button></Link>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
              <div
                className="tab-pane fade accordion-item prr"
                id="profile-tab-pane05"
                role="tabpanel"
                aria-labelledby="profile-tab05"
                tabindex="0"
              >
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse d-lg-block"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#myTabContent"
                >
                   <div className="container">
                      <div className="row gallery align-items-center">
                        <div className="col-sm-12 col-lg-6 mt-5 col-12">
                        <div onClick={() => openPopup(imageSetsMobile[0], 0)}>
                <HoverImageComponent images={imageSetsMobile[0]} />
              </div>
                        </div>

                        <div className="col-sm-12 col-lg-6 mt-5 col-12">
                          <div className="row" style={{ gap: "20px 0px" }}>
                            <div className="col-lg-6 col-6">
                            <div onClick={() => openPopup(imageSetsMobile[0], 0)}>
                <HoverImageComponent images={imageSetsMobile[1]} />
              </div>
                            </div>
                            <div className="col-lg-6 col-6">
                            <div onClick={() => openPopup(imageSetsMobile[0], 0)}>
                <HoverImageComponent images={imageSetsMobile[2]} />
              </div>
                            </div>
                            <div className="col-lg-6 col-6">
                            <div onClick={() => openPopup(imageSetsMobile[0], 0)}>
                <HoverImageComponent images={imageSetsMobile[3]} />
              </div>
                            </div>
                            <div className="col-lg-6 col-6">
                            <div onClick={() => openPopup(imageSetsMobile[0], 0)}>
                <HoverImageComponent images={imageSetsMobile[4]} />
              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="custom-price-button"
                          style={{ paddingTop: "70px" }}
                        >
                          <Link to ="/contact-us"><button>Get Started</button></Link>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
         
        </div>
      </section>
      
    </>
  )
}

export default OurPortfolio;
