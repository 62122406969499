import React, { useEffect } from "react";
import { gsap } from "gsap";
import "../BrandingService/ReadyToStart.css";
import banner from '../../assets/videomain.mp4';

const ReadyToStart = () => {
  // useEffect(() => {
  //   // GSAP Animations
  //   gsap.fromTo(
  //     ".text-box h2 span",
  //     { opacity: 0, y: 50 },
  //     { opacity: 1, y: 0, duration: 1.2, ease: "power3.out", delay: 0.5 }
  //   );

  //   gsap.fromTo(
  //     ".text-box h2",
  //     { opacity: 0, x: -50 },
  //     { opacity: 1, x: 0, duration: 1, ease: "power3.out", delay: 0.2 }
  //   );

  //   gsap.fromTo(
  //     ".text-box p",
  //     { opacity: 0, y: 50 },
  //     { opacity: 1, y: 0, duration: 1.2, ease: "power3.out", delay: 1 }
  //   );

  //   gsap.fromTo(
  //     ".cta-button",
  //     { scale: 0, opacity: 0 },
  //     { scale: 1, opacity: 1, duration: 0.8, ease: "back.out(1.7)", delay: 1.5 }
  //   );
  // }, []);

  return (
    <section className="sec-two container ReadyToStart" id="second">
      {/* Video Background */}
      <video className="background-video" autoPlay loop muted playsInline>
        <source src={banner} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <div className="container">
        <div className="text-overlay">
          <div className="row text-center">
            <div className="text-box">
              <h2>
                Ready To Elevate Your Brand with <span>Apex Web Experts?</span>
              </h2>
              <p>
                Whether you’re looking for a logo designer Melbourne, logo
                design Sydney, or a team to create the perfect Australian logo,{" "}
                <b>
                  <i>Apex Web Experts</i>
                </b>{" "}
                has you covered. Contact us today to discuss your logo design
                needs and take the first step toward creating a brand identity
                that truly represents your business.
              </p>
              {/* <button className="cta-button">Contact Us Today</button> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ReadyToStart;
