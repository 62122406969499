import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import { useInView } from 'react-intersection-observer';
import 'react-vertical-timeline-component/style.min.css';
import { Tilt } from 'react-tilt';
import { Link } from 'react-router-dom';


const StageProcess = () => {
  const { ref: ref1, inView: inView1 } = useInView({ triggerOnce: true });
  const { ref: ref2, inView: inView2 } = useInView({ triggerOnce: true });
  const { ref: ref3, inView: inView3 } = useInView({ triggerOnce: true });
  const { ref: ref4, inView: inView4 } = useInView({ triggerOnce: true });
  const { ref: ref5, inView: inView5 } = useInView({ triggerOnce: true });
  const { ref: ref6, inView: inView6 } = useInView({ triggerOnce: true });
  const { ref: ref7, inView: inView7 } = useInView({ triggerOnce: true });

  return (
    <section className="stage-process">
      <div className="container">
        <div className="row">
          <div>
            <h2 className="section-title">
            Comprehensive Website Development Services  
            </h2>
            <p className='text-white'>
            At Apex Web Experts, we provide a wide range of website development services in Australia to meet your needs:  
            </p>
          </div>
          <div className="col-lg-2"></div>
          <div className="col-lg-8">
            <VerticalTimeline layout="1-column-left">
              <VerticalTimelineElement
                className={`vertical-timeline-element--work ${
                  inView1 ? 'fade-in' : 'fade-out'
                }`}
                contentStyle={{
                  background: 'linear-gradient(177deg, rgb(59 0 140 / 40%), rgb(0 0 0 / 59%))',
                  color: '#fff',
                }}
                contentArrowStyle={{ borderRight: '7px solid #ec008c' }}
                iconStyle={{ background: '#fff', color: '#8100b4' }}
                icon={'01'}
              >
                <div ref={ref1}>
                  <h3 className="vertical-timeline-element-title">Custom Web Development Services    </h3>
                  <p>
                  Your business is unique, and your website should be too. As a leading web development company, we deliver tailored solutions that reflect your brand identity and values. Whether you’re looking for custom web development services or need a custom website design, we bring your ideas to life.  
                  </p>
                </div>
              </VerticalTimelineElement>

              <VerticalTimelineElement
                className={`vertical-timeline-element--work ${
                  inView2 ? 'fade-in' : 'fade-out'
                }`}
                contentStyle={{
                  background: 'linear-gradient(177deg, rgb(59 0 140 / 40%), rgb(0 0 0 / 59%))',
                  color: '#fff',
                }}
                contentArrowStyle={{ borderRight: '7px solid #ec008c' }}
                iconStyle={{ background: '#fff', color: '#8100b4' }}
                icon={'02'}
              >
                <div ref={ref2}>
                  <h3 className="vertical-timeline-element-title">E-commerce Website Development    </h3>
                  <p>
                  For businesses looking to sell online, we create robust, secure, and user-friendly e-commerce websites. As experts in ecommerce web design, we help you establish a seamless shopping experience for your customers, ensuring smooth navigation and secure transactions.  
                  </p>
                </div>
              </VerticalTimelineElement>

              <VerticalTimelineElement
                className={`vertical-timeline-element--work ${
                  inView3 ? 'fade-in' : 'fade-out'
                }`}
                contentStyle={{
                  background: 'linear-gradient(177deg, rgb(59 0 140 / 40%), rgb(0 0 0 / 59%))',
                  color: '#fff',
                }}
                contentArrowStyle={{ borderRight: '7px solid #ec008c' }}
                iconStyle={{ background: '#fff', color: '#8100b4' }}
                icon={'03'}
              >
                <div ref={ref3}>
                  <h3 className="vertical-timeline-element-title">WordPress Website Design  </h3>
                  <p>
                  We specialize in creating visually appealing and high-performing WordPress websites. If you’re searching for WordPress design in Brisbane, look no further than Apex Web Experts. Our WordPress experts deliver responsive and optimized designs tailored to your needs.  
                  </p>
                </div>
              </VerticalTimelineElement>

              <VerticalTimelineElement
                className={`vertical-timeline-element--work ${
                  inView4 ? 'fade-in' : 'fade-out'
                }`}
                contentStyle={{
                  background: 'linear-gradient(177deg, rgb(59 0 140 / 40%), rgb(0 0 0 / 59%))',
                  color: '#fff',
                }}
                contentArrowStyle={{ borderRight: '7px solid #ec008c' }}
                iconStyle={{ background: '#fff', color: '#8100b4' }}
                icon={'04'}
              >
                <div ref={ref4}>
                  <h3 className="vertical-timeline-element-title">Small Business Website Design Packages   </h3>
                  <p>
                  Small businesses require cost-effective yet impactful websites. Our small business website design packages provide affordable solutions without compromising quality, making us a trusted website design and development company in Australia.  
                  </p>
                </div>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className={`vertical-timeline-element--work ${
                  inView5 ? 'fade-in' : 'fade-out'
                }`}
                contentStyle={{
                  background: 'linear-gradient(177deg, rgb(59 0 140 / 40%), rgb(0 0 0 / 59%))',
                  color: '#fff',
                }}
                contentArrowStyle={{ borderRight: '7px solid #ec008c' }}
                iconStyle={{ background: '#fff', color: '#8100b4' }}
                icon={'05'}
              >
                <div ref={ref5}>
                  <h3 className="vertical-timeline-element-title">Industry-Leading Expertise     </h3>
                  <p>
                  Our team of seasoned developers has years of experience providing exceptional web development services in Australia. We stay updated on the latest trends and technologies to create modern, innovative websites.  
                  </p>
                </div>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className={`vertical-timeline-element--work ${
                  inView6 ? 'fade-in' : 'fade-out'
                }`}
                contentStyle={{
                  background: 'linear-gradient(177deg, rgb(59 0 140 / 40%), rgb(0 0 0 / 59%))',
                  color: '#fff',
                }}
                contentArrowStyle={{ borderRight: '7px solid #ec008c' }}
                iconStyle={{ background: '#fff', color: '#8100b4' }}
                icon={'06'}
              >
                <div ref={ref6}>
                  <h3 className="vertical-timeline-element-title">Australian-Focused Web Solutions  </h3>
                  <p>
                  Being a local web development company in Australia, we understand the unique challenges and opportunities businesses face here. . If you’re searching for the best website builder in Australia, we’ve got you covered with custom solutions.  
                  </p>
                </div>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className={`vertical-timeline-element--work ${
                  inView7 ? 'fade-in' : 'fade-out'
                }`}
                contentStyle={{
                  background: 'linear-gradient(177deg, rgb(59 0 140 / 40%), rgb(0 0 0 / 59%))',
                  color: '#fff',
                }}
                contentArrowStyle={{ borderRight: '7px solid #ec008c' }}
                iconStyle={{ background: '#fff', color: '#8100b4' }}
                icon={'07'}
              >
                <div ref={ref7}>
                  <h3 className="vertical-timeline-element-title">SEO-Friendly Websites    </h3>
                  <p>
                  Every website we build is designed with SEO best practices in mind. Our web design services in Australia ensure your site is search-engine friendly, helping you rank higher and attract more visitors.  
                  </p>
                </div>
              </VerticalTimelineElement>
            </VerticalTimeline>
          </div>
          <div className="col-lg-2"></div>
        </div>
     
					<div className="row price-plans-section">
                    <div>
            <h2 className="section-title">
              {/* <span>Pricing</span> */}
              Pricing Plans
            </h2>
          </div>
						<div className="col-sm-12 col-lg-4 mt-5">
                        <div
      className="py-4 price-inner-plan">
								<h2 className="heading-pricing-colors">Standard Package</h2>
								<h3>E-Commerce Basic</h3>
								<div className="price-heading-text">
									<h4>$600</h4>
									<p class="text-sm font-light">per person, per month</p>
								</div>
								<div className="price-list">
									<ul>
										<li>E-Commerce website Design</li>
										<li>Customize Tailor Made Design</li>
										<li>User Friendly</li>
										<li>5 Banner Designs</li>
										<li>Sliding Banner </li>
										<li>5 Stock Images </li>
										<li>100% Money Back Gurantee</li>
										<li>100% Owner Rights</li>
										<li>E-Commerce website Design</li>
										<li>Customize Tailor Made Design</li>
										<li>User Friendly</li>
										<li>5 Banner Designs</li>
									</ul>
								</div>

							</div>
						
						</div>

						<div className="col-sm-12 col-lg-4 mt-5">
                        <div
      className="py-4 price-inner-plan">
								<h2 className="heading-pricing-colors">Premium Package</h2>
									<h3>E-Commerce Professional</h3>
								<div className="price-heading-text">
									<h4>$900</h4>
									<p class="text-sm font-light">per person, per month</p>
								</div>
								<div className="price-list">
									<ul>
										<li>E-Commerce website Design</li>
										<li>Customize Tailor Made Design</li>
										<li>User Friendly</li>
										<li>5 Banner Designs</li>
										<li>Sliding Banner </li>
										<li>5 Stock Images </li>
										<li>100% Money Back Gurantee</li>
										<li>100% Owner Rights</li>
										<li>E-Commerce website Design</li>
										<li>Customize Tailor Made Design</li>
										<li>User Friendly</li>
										<li>5 Banner Designs</li>
									</ul>
								</div>

							</div>
						
						</div>


						<div className="col-sm-12 col-lg-4 mt-5">
                        <div
      className="py-4 price-inner-plan">
								<h2 className="heading-pricing-colors">Signature Package</h2>
									<h3>E-Commerce Corporate</h3>
								<div className="price-heading-text">
									<h4>$1200</h4>
									<p class="text-sm font-light">per person, per month</p>

								</div>
								<div className="price-list">
									<ul>
										<li>E-Commerce website Design</li>
										<li>Customize Tailor Made Design</li>
										<li>User Friendly</li>
										<li>5 Banner Designs</li>
										<li>Sliding Banner </li>
										<li>5 Stock Images </li>
										<li>100% Money Back Gurantee</li>
										<li>100% Owner Rights</li>
										<li>E-Commerce website Design</li>
										<li>Customize Tailor Made Design</li>
										<li>User Friendly</li>
										<li>5 Banner Designs</li>
									</ul>
								</div>

							</div>
						
						</div>

						<div className="custom-price-button" style={{paddingTop: '70px'}}>
            <Link to='/contact-us'><button>
                                Get Started
                            </button>
                            </Link>
						</div>

					</div>
				 
      </div>
    </section>
  );
};

export default StageProcess;
